.share-screen-picker-modal .modal-dialog{
    max-width: 45%;
}
.share-screen-picker-modal .modal-content{
    display: block;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    
}
.share-screen-overlay{
    z-index:100000000 !important;
}
.sources{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-height: 500px;
    overflow-y: auto;
}
.source {
    width: 300px;
    height: 200px;
    cursor: pointer;
}
.source img{
    width: 100%;
    height: 70%;
    box-shadow: 1px 1px 4px #a0a0a0;
    object-fit: cover;
}
.source .title{
    margin-top: 5px;
}
.source.selected img{
    border: 4px solid #2d74da;
}
.share-screen-btn{
    margin-top: 20px;
    border-radius: 0;
    width: 260px;
    height: 50px;
}