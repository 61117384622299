.shared-screen-container {
	position: absolute;
    width: 100%;
	height: 100%;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999;
	background: #2b2c30;
    padding: 0 25px;
}
.shared-screen-container .options {
    display: flex;
    align-items: center;
	padding: 10px 15px;
    background: #58595b;
    margin: 0 auto;
	margin-bottom: 10px;
    border-radius: 16px;
	color: white;
    font-size: 12px;
}
.shared-screen-container .video-container {
	height: calc(100% - 70px);
}
.shared-screen-container .left-side {
	width: 80%;
	float: left;
}
.shared-screen-container .right-side {
    width: 20%;
    float: right;
    font-size: 20px;
    text-align: right;
}
.shared-screen-container .icon {
    cursor: pointer;
}
.shared-screen-container .full-screen-toggle-icon {
    margin-right: 20px;
}
.shared-screen-container .left-side .item {
    display: inline-block;
	margin-right: 25px;
}
.shared-screen-container .left-side .item-title {
    display: block;
    font-size: 11px;
	margin-bottom: 2px;
}
.shared-screen-container select {
    padding: 2px;
}
.shared-screen-container .video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 90px);
    text-align: center;
}
.shared-screen-container .screen-stream-parent{
    overflow: auto;
}
.shared-screen-container .video-container:not(.full) .screen-stream-parent{
    width: 75% !important;
}
.shared-screen-container .video-container:not(.original) .screen-stream-parent .stream-item {
    display: flex;
}
.shared-screen-container .screen-stream {
    width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
.shared-screen-container .video-container.original .screen-stream {
    width: auto;
    height: auto;
    max-height: initial;
    object-fit: none;
}
.shared-screen-container .speaker-stream {
    display: inline-block;
    width: 25%;
    padding-left: 20px;
    vertical-align: middle;
}
.shared-screen-container video {
    width: 100%;
}
.shared-screen-container .speaker-stream .video-item{
    height: auto;
    flex-grow: 1;
}