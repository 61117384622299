:root {
	--color-blue: #0057D3;
	--color-background: lightgray;
	--small: 14px;
	--form-label: 15px;
	--medium: 16px;
	--large: 24px;
	--light-blue: #93C0FF;
	--white: #FFFFFF;
	--block: #000000;
	--red: red;
	--rightside: #E1F5FE;

}

.duration-select,
.edit-duration-select {
	border: 1px solid rgba(0, 0, 0, 0.23);
	padding: 4px 10px 0;
	border-radius: 4px;
}

.defaultImage {
	width: 100%;
	height: auto;
	max-height: 150px;
	display: block;
	border-radius: 5px;
}

.imagePreview-list {
	margin: 0;
	padding: 0;
	list-style: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

#demo-customized-select {
	min-width: auto;
	border-radius: 8px;
	/* border-color: var(--light-blue); */
	border-color: #ECECEC !important;
}

.hide-image {
	position: absolute !important;
	cursor: pointer;
	right: -5px;
	top: -5px;
}

.imagePreview-list li {
	width: 150px;
	height: auto;
	margin-bottom: 5px;
	position: relative;
}

.duration-select1 {
	padding: 3px 10px 2px;
	border: 1px solid var(--block);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-color: var(--light-blue);
}

.select-helpertext {
	position: absolute;
	bottom: -22px;
	left: 10px;
}

.register-helpertext {
	position: absolute;
	/* bottom: -37px; */
	bottom: -25px;
	left: 10px;
}

#demo-customized-select1 {
	padding: 0;
}

#demo-customized-select1:focus {
	background-color: transparent;
}

.customClass {
	margin: 5px 0 6px !important;
}

.customClass .timezomeDemo:before,
.customClass .timezomeDemo:after {
	display: none !important;
}

#chatroom-items {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 65px;
	overflow-y: auto;
	padding: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
}


.add-contact-navlink {
	padding: 1rem 2.5rem !important;
	font-size: 16px;
	font-weight: 500;
	color: #4E78B84D !important;
}

.nav-tabs .add-contact-navlink.active {
	background-color: inherit;
	border-bottom: 1px solid #1967d8 !important;
	color: #4E78B8 !important;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
}

.nav-tabs .add-contact-navlink {
	/* border: none !important; */
	background-color: inherit;
	border-bottom: 1px solid rgba(107, 140, 190, 0.3) !important;
	border-top: 0px !important;
	border-left: 0px !important;
	border-right: 0px !important;

}

#chatroom-items .no_messages {
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

#chatroom-items .no_messages i {
	color: #b3b3b3;
}

#chatroom-items .loader {
	text-align: center;
	margin-bottom: 10px;
	font-size: 16px;
}

#chatroom-items .message {
	width: 400px;
	max-width: 400px;
	display: inline-block;
}

#chatroom-items .mf-content {
	width: 100%;
}

#chatroom-items .message.selected .mf-content {
	background: #7288e8;
	color: white;
}

#chatroom-items .message-feed:not(.right) .mf-content {
	background: #dcdcdc;
}

#chatroom-items .message_file_parent {
	width: 100%;
	margin-top: 10px;
}

#chatroom-items .message_file {
	width: 100%;
	object-fit: cover;
}

#chatroom-items .message_file.fa-file {
	font-size: 60px;
}

#chatroom-items .file_download {
	display: inline-block;
	margin-top: 10px;
}

#chatroom-items .message[data-display_language_id="2"] .message_body {
	text-align: right;
	direction: rtl;
	font-family: "Amiri", serif;
}

#main_content_container {
	height: 100%;
}

.messages-list {
	height: 90%;
	/* overflow-y: scroll; */
}

label.select-label {
	left: 10px;
	z-index: 999;
	top: -6px;
	background: #fff;
	padding: 0 5px;
	display: block;
}

.select-language {
	height: 55px;
	border-radius: 4px !important;
	width: 100%;
}

.header-left-nav {
	display: flex;
	margin-left: -75px;
	align-items: center;
}

.header-sizes {
	justify-content: space-between;
}

.avatar-text {
	font-size: 15px;
	color: #000;
}

.logohead img {
	width: 100%;
	margin-top: 10px;
}



.paginationss {
	display: inline-block;
}

/*.paginationss span {
    float: left;
    text-decoration: none;
    transition: background-color .3s;
    border-radius: 20px;
    color: #4E78B8;
    background-color: #E5E5E5;
    font-size: 14px;
    min-width: 38px;
    height: 38px;
    padding: 9px 0px;
    text-align: center;
}
.paginationss span.active {
	color: #fafafa;
	background: #4E78B8;
	border-radius: 20px;
}
 .paginations span:hover:not(.active) {
	background-color: transparent;
	color: #002964;
} 
.paginationss span:hover{
	text-decoration: none;
}*/
.radio_container__ {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: transparent;
	width: 280px;
	border-radius: 9999px;
	box-shadow: none;
}

.paginationss input[class="radio-appearence-hide"] {
	appearance: none;
	display: none;
}

.days-labels {
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 30px;
	height: 30px;
	text-align: center;
	overflow: hidden;
	transition: linear 0.3s;
	color: #4E78B8;
	background: #E5E5E5;
	border-radius: 100px;
	margin-left: 5px;
}

input[class="radio-appearence-hide"]:checked+label {
	background-color: #1e90ff;
	color: #f1f3f5;
	font-weight: 900;
	transition: 0.3s;
}

/* #profiletabs li.nav-tabs .nav-link{
	border-radius: 20px;
} */
ul#profiletabs li button#profile-tab {
	border: 1px solid #D6DDE7 !important;
	/* border-top-right-radius: 20px;
	border-top-left-radius: 20px; */
border-top-right-radius: 10px;
	border-top-left-radius: 1px; 
}

ul#profiletabs li button#home-tab {
	border: 1px solid #D6DDE7 !important;
	border-top-left-radius: 10px;
	border-top-right-radius: 1px;
}

#profiletabscontent ul li {
	max-width: 95%;
	margin: auto;
	color: #000;
	background: #F4F6FC;
	border-radius: 10px;
	margin-bottom: 10px;
	margin-bottom: 10px;
	padding: 15px 20px;
}

#profiletabscontent ul li p {
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3B414E;
}

#profiletabscontent ul li p:first-child {
	font-size: 14px;
	line-height: 14px;
}

#profiletabscontent ul li.group-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#profiletabscontent ul li.group-list p {
	font-size: 16px;
	line-height: 24px;
}
.intl-tel-input .flag-container .arrow{
	display:none !important
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
	padding-left: 2px !important;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
	display:flex!important
}
.input-arrange {
	display: flex;
}

.input-arrange img {
	margin-right: 14px;
	width: 20px;
}

.input-arrange input,
.input-arrange select {
	height: 41px;
	font-size: 14px;
	line-height: 21px;
	color: #3B414E;
	border: 1px solid #ECECEC;
	border-radius: 10px !important;
}

.input-arrange input {
	padding: 10px 20px;
}

label.label-arrange {
	margin-left: 2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    align-items: center;
    color: rgba(0, 0, 0, 0.38);
	

}

.input-arrange input::placeholder {
	color: rgba(0, 0, 0, 0.38);
}
@media (min-width: 600px){
	.save-profile-photo {
		background: #ECECEC;
		border-radius: 50px;
		padding: 2px 5px; 
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #3B414E;
		width: -moz-fit-content;
		width: fit-content;
		position: relative;
	    top: 127px;
		left: -13px;

		
	}
	.edit-profile-photo {
		background: #ECECEC;
		border-radius: 50px;
		padding: 2px 5px; 
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #3B414E;
		width: -moz-fit-content;
		width: fit-content;
		position: relative;
		top: -25px;
		left: 21px;
	
		
	}
	
}


@media (max-width: 1224px){
	.save-profile-photo {
		background: #ECECEC;
		border-radius: 50px;
		padding: 2px 5px; 
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #3B414E;
		width: -moz-fit-content;
		width: fit-content;
		position: relative;
	    top: 127px;
		left: -13px;

		
	}
	.edit-profile-photo {
		background: #ECECEC;
		border-radius: 50px;
		padding: 2px 5px; 
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #3B414E;
		width: -moz-fit-content;
		width: fit-content;
		position: relative;
	
		top: -36px;
		left: -21px;
	
		
	}
	
}



 .profilename {
	margin-top: 17px;
    margin-left: 17px;
}
.profileimg {
    margin-top: 10px;
}


  .card {

  }
  .userprofile-list-meeting {
	margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3B414E;
  }

@media (max-width: 480px) {
	.addmore_participant{
	padding-left: 16px !important;
	padding-right: 16px !important;
	}
	.profileimg {
		/* margin-top: 11px; */
	}
 .profilename {
	margin-top: 17px;
    margin-left: 24px;
}
	.save-profile-photo{
		background: #ECECEC;
		border-radius: 50px;
		padding: 2px 5px;
		font-weight: 500;
		font-size: 10px;
		line-height: 18px;
		color: #3B414E;
		width: -moz-fit-content;
		/* width: fit-content; */
		width: 115px;
		position: relative;
		top: 78px !important;
		left: -25px !important;
	  }

	  .edit-profile-photo{
		background: #ECECEC;
    border-radius: 50px;
    padding: 2px 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #3B414E;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 289px !important;
    left: 17px !important;
	  }
	.duration_time{

		display:block !important

	}
	#chatroom-items .message {
		width: 300px;
	}

	.searchForm {
		background-color: var(--white) !important;
		border: none !important;
		padding: 15px 25px;
		text-align: center;
		width: 100% !important;
	}

	.maindiv {
		background-color: var(--white);
		text-align: center;
		padding: 0px 25px 15px 25px;
		width: 100%;
	}

	.header-sizes {
		padding-right: 10px;
		display: block !important;
		justify-content: flex-start !important;
	}
	.privacyMargin-20 {
		margin-top: 20px;
		font-size: 14px !important;
	}
	.allowusers p {
		width: 60% !important; 
		font-size: 10px !important;
	}
	.allowusersradio p {
		width: 40% !important; 
		font-size: 10px !important;
	}
	.allowusersmeeting_control p {
		width: 27% !important; 
		font-size: 10px !important;
	}


	.allowuserenablechat_control p {
		width: 10% !important;
		font-size: 10px !important;
	}

	
.addparticipantsMeetingpagepopup{
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 180px;
	display: block;
	overflow: hidden
  }
}

.message-feed a {
	color: #3f82bb !important;
}

.message-feed {
	padding: 0;
	margin-bottom: 15px;
}

.message-feed:last-child {
	margin-bottom: 0;
}

.messageSections {
	height: calc(100% - 90px);
	width: 100%;
	overflow-x: hidden;
	position: relative;
	top: 40px;
}

.right_side {
	background-color: var(--rightside);
	/* display: inline-block; */
	display: block;
	padding: 15px 15px;
	border-radius: 8px;
	margin: 5px;
	display: inline-block;
	color: var(--color-blue);
	word-break: break-all;
}

.left_side {
	display: inline-block;
	border-radius: 8px;
	display: inline-block;
	padding: 15px 15px;
	color: var(--white);
	background-color: var(--color-blue);
	margin-bottom: 0px !important;
}

.name_sender_recever_right {
	color: var(--color-blue);
	font-size: 15px;
	font-weight: bold;
	margin-right: 10px;
}

.name_sender_recever {
	color: var(--color-blue);
	font-size: 15px;
	font-weight: bold;
	margin-right: 10px;
}

.time_color {
	color: #8d8d8d;
	font-size: 10px;
}

.massageBody {
	margin: 10px 10px 0px 10px;
}

span.delete-Image {
	position: absolute;
	left: -7px;
	bottom: 100px;
	cursor: pointer;
}

span.delete-Image svg {
	color: #000;
}

.image-Icon {
	position: absolute;
	bottom: 27px;
	right: 28px;
	color: #ffff;
	border-radius: 1px solid #ffff;
	border-radius: 50%;
	border: 3px solid #ffff;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
	box-shadow: rgb(50 50 93 / 68%) 0px 30px 60px -12px inset, rgb(0 0 0 / 68%) 0px 18px 36px -18px inset;
}

.innerDiv {
	position: relative;
	display: inline-block;
}

.imgsize {
	border-radius: 25px;
	padding: 20px;
	width: 200px;
	height: 200px;
}

.output {
	border-radius: 8px;
	margin: 5px;
	color: #fff;
	background-color: var(--color-blue);
	padding: 7px 12px;
}

.outerset {
	/*padding-top: 5px;*/
	margin-bottom: 10px;
	/* padding-left: 10px;
    padding-right: 10px; */
	display: inline-block;
}

.icon-SIZE {
	font-size: 15px !important;
	padding-left: 6px !important;
	padding-bottom: 2px !important;
}

.massage-border {
	margin-bottom: 10px;
}


.timezoneclass {
	padding-left: 68px;
}

@media only screen and (max-width: 1050px) and (min-width: 750px) {
	.timezoneclass {
		padding-left: 0px;
	}
}

.meetingmaindiv {
	margin-top: -130px !important;
	/* height: calc(100vh - 95px); */
	height: 92vh;
	overflow: auto;
	padding-bottom: 70px !important;
	/* padding-left: 420px ;
	padding-right: 420px ; */
	/* width: 100%; */
	width: 100vw;
	/* max-width: 100% !important; */
	padding-top: 30px !important;
	margin-top: -133px;
}

.mr-5 {
	margin: 5px !important;
}

.mainbtn {
	/* margin-top: 3px !important; */
	/* background: #2d74da !important; */
	/* background-color:  var(--color-blue) ;
	border-radius: 30px !important;
	padding: 7px 13px !important;
	text-transform: none !important;
	border: 1px solid var(--color-blue) !important;
	text-decoration: none !important; */
	/* color: #fff !important; */
	/* color: var(--white);
	font-size: 15px;
	margin: 0px 5px;
	outline: none; */
	margin-top: 3px  !important;
	background: transparent;
	border-radius: 30px !important;
	padding: 7px 5px 7px 13px !important;
	text-transform: none !important;
	text-decoration: none !important;
	border: 0px;
	margin: 0px 5px;
	outline: none;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	color: #335483;
}

.headerMainBtn.contactbtn:hover {
	background-color: transparent;
}

/* .mainbtn:hover{
	background-color: var(--white);
	color: var(--color-blue);
	border: 1px solid var(--color-blue) !important;

} */
/* #date-picker-dialog {
  padding: 18px 25px;
}
#time-picker {
  padding: 18px 25px;
} */

.display {
	display: flex;
}

#date-picker-dialog {
	width: 95% !important;
	padding: 9px 4px 9px 4px !important;
}

#time-picker {
	width: 95% !important;
	padding: 9px 4px 9px 4px !important;
}

.dayWidth {
	width: 75px !important;
}

.timeWidth1 {
	width: 116px;
	margin-left: 50px;
}

.timeWidth2 {
	width: 108px;
}

.timeZoneWidth {
	width: 106px;
	padding-left: 24px !important;
	padding-top: 24px !important;
	margin-left: 26px;
}

.inputTitle {
	margin-left: 10px;
}

.inputDescription {
	margin-left: 20px;
}

.passwordInput {
	margin-left: 50px;
	padding: 8px 8px 8px 0px !important;
}

.checkboxField {
	margin-left: 10px;
}

.hostcheck {
	/* margin-left: 25px;
  margin-top: 4px; */
	margin-left: 0px;
	margin-top: 0px;
}

.particheck {
	/* margin-left: 25px;
  margin-top: 4px; */
	margin-left: 0px;
	margin-top: 0px;
}

.participatcheck {
	margin-left: 0px;
}

.chatcheck {
	margin-left: 5px;
	/* margin-top: 4px; */
	/* margin-left: 0px; */
	margin-top: 42px;
}

.privacycheck {
	margin-left: 0px;
	margin-top: 0px;
}

/* .table {
	margin-left: 25px;
	margin-top: -10px;
} */

.outer-border.chat-options p {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #4E78B8;
	margin-bottom: 0px;
}

.outer-border.chat-options label {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #3B414E;
	text-transform: capitalize;
}

.outer-border.meetingslist ul li {
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #3B414E;
	line-height: 45px;
}

li.custom-style-space:focus {
	background: transparent !important;
}

.outer-border.meetingslist ul li img {
	width: auto;
	height: 22px;
	margin-right: 19px;
}

.outer-border.meetingslist ul li p {
	margin-bottom: 0;
	display: flex;
	align-items: inherit;
}

.outer-border.meetingslist ul li span {
	margin-left: 10px;
	line-height: 30px;
}

.outer-border.meetingslist h3,
.outer-border.description h3,
.outer-border.participants h3,
.CustomPopUp.Participants_List h6,
.CustomPopUp.Meeting_Participants h6,
.Meeting_Participants h6 {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	color: #4E78B8;
}
.Meeting_Participants h6 {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	color: #4E78B8;
}
.meeting-popup h6 {
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	color: #4E78B8;
}


.outer-border.description p,
.outer-border.participants p {
	font-weight: 400;
	font-size: 14px;
	color: #3B414E;
}

.outer-border.participants span {
/* 	font-weight: 600;
	font-size: 14px;
	line-height: 21px; */
	color: #88acde;
	cursor: pointer;
}

.outer-border.participants img {
	width: 40.75px;
	height: 40.75px;
	margin-bottom: 5px;
}

.meetingslist-inner {
	display: flex;
	align-items: baseline;
}

.meetingslist-inner p {
	line-height: initial;
}

.dropdown-buttons-description {
	display: flex;
}

.custom-style-icon {
	min-width: 25px !important;
}

.custom-style-desc span {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #000;
}

li.custom-style-space {
	padding: 0px 18px !important;
}

.custom-style-icon.blue svg {
	color: #4E78B8;
}

.custom-style-icon.red svg {
	color: #E05047;
}

/* button.delete {
	background: #E05047;
	color: #fff;
} */
button.delete {
	background: #E05047;
	color: #fff;
	border: 0px;
	padding: 7px 15px;
	border-radius: 5px;
	text-transform: none;
}
button.deleteRecurences {
	background: #e8173d;
	color: #fff;
	border: 0px;
	padding: 7px 15px;
	border-radius: 5px;
	text-transform: none;
}


button.editRecurences{
	background: #0956b5;
	color: #fff;
	border: 0px;
	padding: 7px 15px;
	border-radius: 5px;
	text-transform: none;
}



button.deleteall {
	
	
	border: 1px solid #dfe3e8;
	padding: 7px 15px;
	border-radius: 5px;
	text-transform: none;
}

button.editall {
	
	
	border: 1px solid #dfe3e8;
	padding: 7px 15px;
	border-radius: 5px;
	text-transform: none;
}


button.cancel {
	background: #E5E5E5;
	color: #000;
	text-transform: initial;
}

button.delete:hover,
button.cancel:hover {
	background: #E5E5E5;
	color: #000;
}

button.download:hover,
button.download {
	background: #4E78B8;
	border-radius: 5px;
	color: #fff;
	text-transform: initial;
	padding: 7px 30px;

}

.dropdown-buttons-description button {
	background: #F4F6FC;
	color: #000000;
	padding: 10px 5px;
	min-height: 44px;
}

.dropdown-buttons-description button.AddFilesBtn {
	border: 1px dashed #ADAEAF;
	border-radius: 10px;
	background: transparent;
	color: #3B414E;
}

.dropdown-buttons-description button.AddFilesBtn svg {
	color: #000;
}

.dropdown-buttons-description svg {
	color: #B0BDCF;
}

.dropdown-buttons-description span {
	font-size: 13px !important;
	font-weight: 400;
	line-height: 20px;
}

.header-call-start-button {
	background: transparent;
	border: none;
}
.start_the_call {
	min-width: 70px;
}
.header-call-start-button .start_the_call {
	width: 120px;
	height: 50px;
	border-radius: 30px;
}
.start_the_call.in-progress {
	background: #3dca5e;
}


@media screen and (max-width: 992px) {  
		
	
	.header-call-start-button {
background: transparent;
border: none;
text-align: center;
float: right;
}

}



@media screen and (max-width: 780px) {  
		
		
	.header-call-start-button {
background: transparent;
border: none;
text-align: center;
float: right;
position: relative !important;
}

}


 

@media only screen and (min-width: 768px)  {
	
/* 	.meeting-scehdule-detail {
		width: 100%;
		margin: 0 auto;
	
		position: relative;
		top: 32px;
		overflow: auto;
		overflow-x: hidden;
		margin-top: 24% !important;
	} */



	.header-call-start-button {
		background: transparent;
    border: none;
    /* margin: -9px; */
/*     margin-top: -27px !important; */

  
  }

  svg.EditHeaderIcons {
	color: #1c488e  !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
    margin-left: -3px; 
  position: absolute;
}


svg.edit {
	color: #1c488e  !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
   /*  margin-left: -29px; */
   position: absolute;
}
svg.Delete {
	color: #5976A4 !important;
  margin-right: -17px!important; 
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
   /*  margin-left: -29px; */
   position: absolute;

}

}
  @media screen and (max-width: 480px) {
	/* .meeting-scehdule-detail {
		width: 100%;
		margin: 0 auto;

		position: relative;
		top: 32px;
		overflow: auto;
		overflow-x: hidden;
		margin-top: 24% !important;
	} */
	.header-call-start-button {
		background: transparent;
    border: none;
    /* margin: -9px; */
 	margin-top: -22px !important; 
	position: absolute;
  
  }
  svg.EditHeaderIcons {
	color: #1c488e !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
    margin-left: -3px; 
  position: absolute;
}


svg.edit {
	color: #1c488e  !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
   /*  margin-left: -29px; */
   position: absolute;
}
svg.Delete {
	color: #5976A4 !important;
  margin-right: -17px!important; 
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: -20px ;
    border-radius: 0px;
   /*  margin-left: -29px; */
   position: absolute;

}


button.delete {
	background: #E05047;
	color: #fff;
	border: 0px;
	padding: 7px 30px;
	border-radius: 5px;
}

  }



.detailheader button.header-call-button {
	background: #4E78B8;
	border-radius: 20px;
	color: #fff;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	padding: 14px 27px;
	border: 0px;
}

h6.detailheaderhading {
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	color: #3B414E;
}

.CustomPopUp .MuiPaper-root-851 {
	border-radius: 20px !important;
	width: 425px !important;
}

.CustomPopUp.Participants_List .MuiPaper-root-851 {
	width: 650px !important;
	position: fixed;
	top: 0%;
}

.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
	width: 950px !important;
	/* position: fixed;
    top: 25%; */
	max-width: inherit;
}

.CustomPopUp.Meeting_Participants .table {
	width: -webkit-fill-available;
	margin-top: -15px;
	margin-left: 0px;
	margin-bottom: -15px;
}

.Participants_List_Modal {
	position: relative;
	height: 200px;
	overflow-y: scroll;
}

.Participants_List_Modal_Inner ul {
	position: absolute;
	top: 0 !important;
}

.tableimage img {
	width: 45px;
	height: 45px;
	border-radius: 50px;
	position: relative;
}

.CustomPopUp.Meeting_Participants .table th {
	width: 70px;
	padding: 5px 0px 5px 15px;
	margin-left: auto;
    margin-right: auto;
}

.CustomPopUp.Meeting_Participants table tr td:last-child {
	text-align: right;
}

.CustomPopUp.Add_Participant .MuiPaper-root-851 {
	width: 425px !important;
}

.CustomPopUp.Add_Participant .table th {
	width: 70px;
	padding: 5px 0px 5px 15px;
}

.CustomPopUp.Add_Participant .table tr {
	border: 1px solid #EEEEEE;
}

p.meetingfooterpara {
	margin-bottom: 0px;
	background: #EEF2FF;
	border-radius: 5px;
	color: #4E78B8 !important;
	padding: 9px;
}

.tableimage {
	position: relative;
}

.tableimage-inner img {
	width: 18px;
	height: 18px;
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.MuiDialogContent-root-1023::-webkit-scrollbar,
.Participants_List_Modal::-webkit-scrollbar,
.add-participant-scroll::-webkit-scrollbar {
	display: none;
}

.add-participant-scroll {
	height: 230px;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

svg.EditHeaderIcons {
	color: #1c488e !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: 0px;
    border-radius: 0px;
   /*  margin-left: -29px; */
}


svg.edit {
	color: #1c488e  !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: 0px;
    top: 0px;
    border-radius: 0px;
   /*  margin-left: -29px; */
}
svg.Delete {
	color: #5976A4 !important;
 /*    margin-right: -17px!important; */
    /* font-size: 2rem !important; */
    cursor: pointer;
    /* height: 93.437696px; */
    /* width: 19px; */
    left: -5px;
    top: 0px;
    border-radius: 0px;
   /*  margin-left: -29px; */
}
button.header-call-button img {
	width: 24px;
}

svg.EditHeaderIconsCall {
	color: #fff !important;
	font-size: 2rem !important;
}

.Add_Participant .nav-pills .nav-link {
	color: rgba(107, 140, 190, 0.3);
	;
	background-color: transparent;
	border-bottom: 1px solid rgba(107, 140, 190, 0.3) !important;
	border: 0;
	border-radius: 0;
	width: 100%;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.Add_Participant .nav-pills .nav-link.active {
	color: #4E78B8;
	background-color: transparent;
	border-bottom: 1px solid #4E78B8 !important;
	border: 0;
	border-radius: 0;
}

span.contact-participant img {
	width: 23px;
	height: 23px;
	border-radius: 50%;
	margin-right: 3px;
}

span.contact-participant {
	background: #ECF2F8;
	border-radius: 10px;
	padding: 5px 5px;
	position: relative;
	width: fit-content;
	display: inline-block;
	margin-bottom: 5PX;
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	margin-right: 10px;
}

span.contact-participant-inner img {
	position: absolute;
	top: 0;
	right: -10px;
	width: 15px;
	height: 15px;
}

.email-participant {
	position: relative;
}

.email-participant button,
.email-participant button:hover {
	position: absolute;
	right: 1px;
	top: 1px;
	bottom: 1px;
	min-width: 96px;
	border-radius: 10px;
}

.has-search .form-control::placeholder {
	font-size: 14px;
	color: #C2C2C2;
}

@media only screen and (max-width: 1279px) {
	.dayWidth {
		width: 100px !important;
	}

	.timeWidth1 {
		width: 108px;
		margin-left: 0px;
	}

	.timeWidth2 {
		width: 108px;
	}

	.timeZoneWidth {
		width: 116px;
		padding-left: 24px !important;
		padding-top: 24px !important;
		margin-left: 0px;
	}

	.inputTitle {
		margin-left: 0px;
	}

	.inputDescription {
		margin-left: 42px;
	}

	.passwordInput {
		margin-left: 50px;
	}

	.checkboxField {
		margin-left: 10px;
	}

	.participatcheck {
		margin-left: 0px;
	}

	.chatcheck {
		margin-left: 5px;
	}

	.privacycheck {
		margin-left: 0px;
	}

	.table {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 1100px) {
	.m-10 {
		display: grid !important;
	}

	/* .display {
    display: block;
  } */
	.timezomeDemo {
		margin-top: 0px !important;
	}

	.duration-select {
		padding: 7px 10px 3px;
	}

	.edit-duration-select {
		padding: 7px 10px 3px;
		margin: 0 0 21px;
	}

	.duration-select1 {
		padding: 7px 10px 3px;
	}

	.dayWidth {
		width: 100px !important;
	}

	.timeWidth1 {
		width: 108px;
	}

	.timeWidth2 {
		width: 108px;
	}

	.timeZoneWidth {
		width: 116px;
		padding-left: 24px !important;
		padding-top: 24px !important;
	}

	#demo-customized-select {
		padding: 11px 21px;
	}

	/* .joinMeetingBtn {
		margin-top: 4px !important;
		background: #ffffff !important;
		border-radius: 30px !important;
		padding: 5px 13px !important;
		text-transform: none !important;
		border: 1px solid #2d74da !important;
		text-decoration: none !important;
		color: #2d74da !important;
		font-size: 15px;
		top: -8px;
		outline: none;
	} */

	.profileBtn {
		padding: 0px;
		margin-left: -5px;
		font-size: 14px;
		font-weight: 400;
		color: #2d74da;
	}

	#date-picker-dialog {
		width: 100% !important;
		padding: 12px 6px !important;
	}

	#time-picker {
		width: 100% !important;
		padding: 12px 6px !important;
	}

	.mainTitle {
		width: 116px;
	}

	.inputTitle {
		margin-left: 10px;
	}

	.mainDescription {
		padding: 24px 5px 24px 24px !important;
		margin-left: -3px;
	}

	.inputDescription {
		margin-left: 48px;
	}

	.passwordInput {
		margin-left: 50px;
	}

	.checkboxField {
		margin-left: 10px;
	}

	.participatcheck {
		margin-left: 0px;
	}

	.chatcheck {
		margin-left: 5px;
	}

	.privacycheck {
		margin-left: 0px;
	}

	.table {
		margin-left: 25px;
	}

	.privacyMargin-20 {
		margin-top: 20px;
	}
}

@media (max-width:1100px) and (min-width:991px) {
	.header-left-nav {
	/* 	margin-left: 200px; */

	}

	.header-left-nav {
		    margin-left: -47px !important;
			margin-right: -25px;
		}


	.logohead img {
		width: 80%;
		margin-top: 10px;
	}

	.header-left-nav .mainbtn {
		padding: 7px 5px 7px 5px !important;
	}
}

@media only screen and (max-width: 960px) {
	.m-10 {
		display: grid !important;
	}

	.display {
		display: block;
	}

	.inputTitle {
		margin-left: 0px;
	}

	.inputDescription {
		margin-left: 0px;
	}

	.passwordInput {
		margin-left: 75px;
	}

	.checkboxField {
		margin-left: 10px;
	}

	.hostcheck {
		margin-left: 0px;
	}

	.particheck {
		margin-left: 0px;
	}

	.participatcheck {
		margin-left: 0px;
	}

	.chatcheck {
		margin-left: 0px;
	}

	.privacycheck {
		margin-left: 0px;
	}

	.table {
		margin-left: 25px;
	}

	.joinMeeting {
		width: 80% !important;

		height: 210px;

		padding: 20px;
		border-radius: 25px;
		outline: none;
	}
}

@media (max-width: 1279px) and (min-width:300px) {
	.inputDescription {
		margin-left: 0px !important;
	}

	.accordion-height {
		min-height: initial !important;
	}

	.marginpopup.marginmeetingschedule {
		margin: 10px 10px 20px 0px !important;
	}

	.recurring-toggle label {
		margin-left: 0px !important;
	}

	.marginpopup.timezonemargin {
		margin-right: 0px !important;
	}

	





	
}

@media (max-width: 600px) and (min-width:300px) {
	.marginpopup.marginmeetingschedule {
		margin: 10px 0px 20px 0px !important;
	}
}

#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.17);
	z-index: 2;
	cursor: pointer;
}

.closeBtn {
	float: right;
	margin-top: 0px;
	margin-left: 2.5%;
	background-color: transparent;
	border: none;
	position: absolute;
	color: #2d74da;
	text-decoration: underline;
}

.closeBtnJoinMeeting {
	float: right;
	margin-top: -12px;
	/* margin-left: 44%; */
	background-color: transparent;
	border: none;
	position: absolute;
	color: #988f8f;
	right: 30px;

}

.paper {
	width: 68%;
	border: 1px solid #000;
	height: 580px;
	overflow: auto;
	min-width: 600px;
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
	border-radius: 12px;
	background-color: #fff;
	outline: none;
	padding-bottom: 0%;
}

.meetingtabs .sidebar2 .Mui-selected {
	border-bottom: 1px solid #6B8CBE !important;
	font-weight: 600 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #6B8CBE !important;
	border-radius: 0px !important;
	background-color: transparent !important;
	
}

.meetingtabs .sidebar2 button.previous,
.meetingtabs .sidebar2 button.Upcoming {
	color: #6B8CBE !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	border-bottom: 1px solid #6B8CBE !important;
	opacity: 0.4;
}

.meetingtabs .sidebar2 .start_join {
	right: 50px;
}

.meetingtabs {
/* 	display: flex; */
/* 	padding: 6px 28px 0 0px; */
	padding: 16px;
    position: relative;
}





@media only screen and (max-width: 1180px) and (min-width: 960px) {
	.paper {
		width: 83% !important;
	}
}

@media only screen and (max-width: 1450px) and (min-width: 1280px) {
	.paper {
		width: 78% !important;
	}
}

.closeBtnProfile {
	float: right;
	margin-top: -44px;
	margin-left: 6%;
	background-color: transparent;
	border: none;
	position: absolute;
	color: #988f8f;
}

@media (max-width:800px) {
	.header-sizes {
		justify-content: flex-end;
	}

	.header-left-nav {
		margin-left: initial;
	}
}

@media only screen and (max-width: 599px) {
	.closeBtnProfile {
		margin-left: 28%;
	}

	.topRightPart {
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}

	.containerpadding {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.joinMeeting {
		width: 80% !important;

		height: 210px !important;
		/* overflow: auto; */
		padding: 20px;
		border-radius: 25px;
		outline: none;
	}

	.searchbar {
		width: 77% !important;
		margin-left: 10px;
		margin-top: 4px;
		margin-right: 10px;
	}

	.closeBtn {
		margin-top: 0px;
		margin-left: 0px !important;
		background-color: transparent;
		border: none;
		position: absolute;
		color: #2d74da;
		text-decoration: underline;
		right: 13px;
	}



	.Upcoming {
		min-width: 42% !important;
		text-transform: none !important;
		color: rgba(0, 0, 0, 0.54) !important;
		border-radius: 10px 10px 0px 0px !important;
		font-size: 14px !important;
		min-height: 40px !important;
	}

	.outerbox {
		background-color: #dcdcdc !important;
		color: #2d74da !important;
		min-width: 42% !important;
		text-transform: none !important;
		border-radius: 10px 10px 0px 0px !important;
		font-size: 14px !important;
		min-height: 40px !important;
	}

	.previous {
		min-width: 42% !important;
		text-transform: none !important;
		border-radius: 10px 10px 0px 0px !important;
		color: rgba(0, 0, 0, 0.54) !important;
		font-size: 14px !important;
		min-height: 40px !important;
	/* 	background-color: #f6f6f6 !important; */
	}

	.paper {
		width: 100%;
		border: 1px solid #000;
		height: 75%;
		min-height: 300px;
		overflow: auto;
		min-width: 320px;
		box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
		border-radius: 12px;
		background-color: #fff;
		outline: none;
	}

	.table {
		width: 100% !important;
		margin-bottom: 1rem;
		color: #212529;
		/* Yoonus commented this remove extra spacing in mobile View */
		/* margin-left: 10px !important; */
	}


}

@media only screen and (max-height: 500px) {
	


	.paper {
		width: 100%;
		border: 1px solid #000;
		height: 250px;
		min-height: 300px;
		overflow: auto;
		min-width: 320px;
		box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
		border-radius: 12px;
		background-color: #fff;
		outline: none;
	}


}

input:-internal-autofill-selected {
	appearance: menulist-button;
	background-color: #dcdcdc !important;
	background-image: none !important;
	color: -internal-light-dark(black, white) !important;
}

.marginpopup {
	margin: 10px 10px 20px 10px !important;
}

.passwordSection {
	display: flex;
	margin-left: 10px;
}

.checkpasword {
	margin-top: -10px;
}

.ml-25 {
	margin-left: 25px;
}

.contactBox {
	border: 1px solid #e5e5e5;
	height: 300px;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	display: flex;
}

.contactTiles {
	/* border: 1px solid #e5e5e5; */
	margin: 15px;
}

.img-size-Schedule {
	width: 36px;
	height: 36px;
	border-radius: 50% !important;
	border: 1px solid #c4c4c4;
	margin-right: 14px;
	margin-top: 8px;
}

.nameConatcts {
	white-space: nowrap;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nameEmail {
	white-space: nowrap;
	width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 1580px) and (min-width: 1280px) {
	

	.nameEmail {
		white-space: nowrap;
		width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.MuiPaper-elevation1-267 {
	box-shadow: none !important;
}

.has-search .form-control {
	padding-left: 2.375rem;
	border-radius: 12px !important;
	border-color: var(--light-blue) !important;
	min-height: 45px;
}

.Add_Participant .has-search .form-control {
	border-color: #E0E1E2 !important;
	border-radius: 10px;
}

.form-group.has-search.email-participant svg,
.form-group.has-search.contacts-participant svg {
	width: 20px;
	margin-top: 11px;
}

.has-search .form-control-feedback {
	position: absolute;
	z-index: 2;
	display: block;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	text-align: center;
	pointer-events: none;
	color: #aaa;
}

.searchbar {
	width: 98%;
	margin-left: 10px;
	margin-top: 4px;
}

.searchIcon {
	position: absolute;
	z-index: 2;
	display: block;
	line-height: 2.375rem;
	text-align: center;
	pointer-events: none;
	color: #aaa;
	margin-left: 15px;
}

.contactMainBox {
	display: flex;
	overflow: auto;
	flex-wrap: wrap;
	margin-top: -24px;
	margin-left: -25px;
	align-content: flex-start;
	flex-direction: row;
	justify-content: flex-start;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

/* #outlined-margin-dense {
	padding: 14px 16px !important;
} */
#outlined-margin-dense {
	padding: 15px 20px;
}

.Onclick {
	/* background-color: #2d74da !important; */
	color: #ffffff !important;
	/* border-radius: 10px 10px 0px 0px !important; */
	text-transform: none !important;
	opacity: 1 !important;
	font-size: 17px !important;
	width: 125px !important;
	z-index: 1;
	margin-right: 4px !important;
}

.cancelBtn {
	background: transparent;
	border: 1px solid var(--color-blue) !important;
	font-size: 14px !important;
	color: var(--color-blue) !important;
	/* text-decoration: underline !important; */
	margin-top: 8px;
	text-transform: capitalize !important;
	width: 100% !important;
	padding: 10px 10px 10px 0px !important;
	border-radius: 8px !important;


}

.tabContact {
	border: 1px solid #e5e5e5;
	padding: 0px 62px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.tabEmail {
	border: 1px solid #e5e5e5;
	padding: 0px 73px;
	margin-left: 5px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.onActive {
	border: 1px solid #e5e5e5;
	padding: 0px 73px;
	margin-left: 5px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background-color: #2d74da;
	color: #ffffff;
}

.Mui-selected {
	background-color: var(--color-blue) !important;
	color: var(--white) !important;
}

.inviteBtn {
	margin-top: 10px !important;
	background-color: var(--white) !important;
	border: 1px solid var(--light-blue) !important;
	border-color: var(--light-blue) !important;
	border-radius: 12px !important;
	color: var(--color-blue) !important;
	text-transform: capitalize !important;
}

.MuiIconButton-root {
	padding: 0px !important;
}




@media (min-width: 1280px) {
	.dailogcontect-shedulehost {
		
				width: 600px;
			}
		}


@media  (max-width: 480px) and (min-width: 280px) {
	
	
			.dailogcontect{
				width: 340px;
			}

			.dailogcontect-shedulehost{
			/* 	width: 340px; */
			}

		.ellipsisMoreParticipants{
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 152px ;
			display: block;
			overflow: hidden;
			font-size: 12px;
		/* 	margin:0px -108px 0px 3px; */
		}

.Notify{
	width:14.079999923706055px;
	height: 18px;
}
.AssignCo-Host{
	width:14.079999923706055px;
	height: 18px;
}

.Remove{
	width:14.079999923706055px;
	height: 18px;
}
.tableimage{
	width:39.08px;
	height: 56px;

}
.tableimage-inner{
	width:14.079999923706055px;
	height: 18px;
}

	.header-sizes {
		display: flex !important;
		/* width: 320px; */
		font-size: 10px;
		margin-left: 12px;
		margin-top: 18px;
	}


	p.meetingfooterpara {
		margin-bottom: 13px;
	background: #EEF2FF;
	border-radius: 5px;
	color: #4E78B8 !important;
		padding: 5%;
		text-align: center;
	}
	

	
	
	
	.CustomPopUp.Meeting_Participants .table th {
		width: 60px;
		padding: 5px 0px 5px 15px;
		margin-left: auto;
		margin-right: auto;
	}


	.action_buttons{
		display: inline-block !important;
	}

	/* .mainbtn { */
	/* margin-top: 3px !important; */
	/* background: #2d74da !important; */
	/* background: #ffffff !important;
    border-radius: 30px !important;
    padding: 3px 20px !important;
    text-transform: none !important;
    border: 1px solid #2d74da !important;
    text-decoration: none !important; */
	/* color: #fff !important; */
	/* color: #2d74da !important;
    font-size: 13px;
    margin: 0px 6px !important;
    outline: none;
    line-height: 14px; */
	/* } */
	/* .joinMeetingBtn {
    margin-top: 1px !important;
    background: #ffffff !important;
    border-radius: 30px !important;
    padding: 3px 11px !important;
    text-transform: none !important;
    border: 1px solid #2d74da !important;
    text-decoration: none !important;
    color: #2d74da !important;
    font-size: 13px !important;
    top: -8px;
    outline: none;
    line-height: 14px;
	margin-left: 12px !important;
} */

}


@media only screen and (max-width: 480px) and (min-width: 320px) {
	


	.searchForm {
		background-color: var(--white) !important;
		border: none !important;
		padding: 15px 25px;
		text-align: center;
		width: 100% !important;
	}

	.maindiv {
		background-color: var(--white);
		text-align: center;
		padding: 0px 25px 15px 25px;
		width: 100%;
	}


	.ellipsisMoreParticipants{
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 152px ;
		display: block;
		overflow: hidden;
		font-size: 12px;
		/* margin:0px -108px 0px 3px; */
	}

.Notify{
width:14.079999923706055px;
height: 18px;
}
.AssignCo-Host{
width:14.079999923706055px;
height: 18px;
}

.Remove{
width:14.079999923706055px;
height: 18px;
}
.tableimage{
width:39.08px;
height: 56px;

}
.tableimage-inner{
width:14.079999923706055px;
height: 18px;
}

.header-sizes {
	display: flex !important;
	/* width: 320px; */
	font-size: 10px;
	margin-left: 12px;
	margin-top: 18px;
}


p.meetingfooterpara {
	margin-bottom: 13px;
background: #EEF2FF;
border-radius: 5px;
color: #4E78B8 !important;
    padding: 5%;
    text-align: center;
}





.CustomPopUp.Meeting_Participants .table th {
	width: 60px;
	padding: 5px 0px 5px 15px;
	margin-left: auto;
	margin-right: auto;
}


.action_buttons{
	display: inline-block !important;
}
}





.topRightPart {
	justify-content: flex-end;
	align-items: flex-end;
	/* display: block !important; */
}
.moveStarLeft{
	justify-content: flex-end;
	align-items: flex-end;
	display: flex;
	margin-left: auto;
}
.width-70pc {
	width: 65% !important;
}
.profilewidth {
	min-width: 600px;
	background-color: #ffffff;
	border: 1px solid #000;

	border-radius: 45px;
	width: 43%;


	height: 515px;
	overflow: auto;
}

.joinMeeting {
	width: 650px;
	background-color: #fff;
	height: 403px;
	padding: 20px;
	border-radius: 25px;
	outline: none;
}
.meetingdetailcross {
	display: none;
}

@media  (max-width:480px){
	.archived{
		font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
/* identical to box height */


align-items: center;

color: #6B8CBE;
	}
	.meetingmaindiv.open {
		/* display: block;
		position: fixed;
		padding-left: 18px !important;
		left: 0;
		top: 0px;
		z-index: 200;
		background: #fff; */

 
		height: calc(100vh - 168px) !important;
		display: block;
    position: fixed;
    padding-left: 19px !important;
    left: 0;
    top: 280px !important;
    z-index: 200;
    background: #fff;
	padding-top: 18px !important;
	}
}
@media (max-width:991px) {


	.meetingmaindiv {
		display: none;
	}
	.meetingmaindiv.open {
		/* display: block;
		position: fixed;
		padding-left: 18px !important;
		left: 0;
		top: 0px;
		z-index: 200;
		background: #fff; */

		height: calc(100vh - 168px) !important;
		display: block;
    position: fixed;
    padding-left: 19px !important;
    left: 0;
    top: 290px !important;
    z-index: 200;
    background: #fff;
	padding-top: 18px !important;
	}
	.meetingdetailcross svg {
		margin-right: -3px;
		font-size: 24px;
		color: #4E78B8;
	}
	.meetingdetailcross {
		
text-align: end; 
    display: block;
   margin-left: auto; 
    margin-top: -18px;
  /*   margin-left: 325px; */
	}
	.meetingdivHide{
     display: none !important;		
	}

	.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
		width: 700px !important;
	}
}

@media only screen and (max-width: 768px) {
	

	.closeBtnJoinMeeting {
		float: right;
		margin-top: -12px;
		/* margin-left: 44%; */
		background-color: transparent;
		border: none;
		position: absolute;
		color: #988f8f;
		right: 48px !important;
	}

	.search-contacts .add-contact-parent {
		position: relative;
		margin-top: 20px;
		text-align: right;
	}

	.search-contacts .contact {
		border-bottom: 1px solid #f2f2f2 !important;
	}

	.search-contacts .listitem {
		border-bottom: none !important;
	}

	svg.EditHeaderIcons {
		color: #5976A4 !important;
		margin-right: 0.8rem !important;
		font-size: 1.4rem !important;
	}

	.detailheader button.header-call-button {
		font-size: 18px;
		padding: 3px 16px;
	}

	svg.EditHeaderIconsCall {
		font-size: 1.4rem !important;
	}

	h6.detailheaderhading {
		font-size: 16px;
		margin-bottom: 21px;
	}

	.CustomPopUp.Participants_List .MuiPaper-root-851,
	.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
		width: 500px !important;
	}

	img.CallButtonImage {
		width: 16px;
		height: 16px;
	}

	.CustomPopUp.Meeting_Participants table tr td:last-child {
		display: flex;
	}

	.ellipsisMoreParticipants{
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 152px ;
		display: block;
		overflow: hidden;
		font-size: 12px;
		/* margin:0px -108px 0px 3px; */
	}

.Notify{
width:14.079999923706055px;
height: 18px;
}
.AssignCo-Host{
width:14.079999923706055px;
height: 18px;
}

.Remove{
width:14.079999923706055px;
height: 18px;
}
.tableimage{
width:39.08px;
height: 56px;

}
.tableimage-inner{
width:14.079999923706055px;
height: 18px;
}

.header-sizes {
	display: flex !important;
	/* width: 320px; */
	font-size: 10px;
	margin-left: 12px;
	margin-top: 18px;
}

p.meetingfooterpara {
	margin-bottom: 13px;
background: #EEF2FF;
border-radius: 5px;
color: #4E78B8 !important;
    padding: 5%;
    text-align: center;
}





.CustomPopUp.Meeting_Participants .table th {
	width: 60px;
	padding: 5px 0px 5px 15px;
	margin-left: auto;
	margin-right: auto;
}


.action_buttons{
/* 	display: inline-block !important; */
}
}

.CloseIcon {
	width: 16px !important;
	height: 16px !important;
	color: var(--block);
	margin-top: 10px !important;
	margin-left: 250px;
}

.CloseIcoN {
	width: 16px !important;
	height: 16px !important;
	color: var(--block);
	margin-top: 10px !important;
}



.Schedule_Meeting {
	margin-top: 35px !important;
	/* margin-left: 15px; */
	/* display: flex;
	justify-content: center;
	align-items: center; */
	padding-bottom: 15px;
	/* text-align: center; */
	font-size: 25px !important;

}

/* 
 @media (max-width: 768px) {
	.Schedule_Meeting {
		font-weight: 500;
		font-size: 25px;
		line-height: 30px;


	}
}  */
.Schedule_Meeting{
	font-weight: 500 !important;
	font-size: 25px !important;
	line-height: 30px;
		

}
@media (max-width: 480px) {

	.meeting-duration{
		font-size: 14px !important;
		text-align: center;
	  }
	.titleParticepentlabel span{
	
		font-weight: 400 !important;
		font-size: 10px !important;
		line-height: 15px !important;
	
		align-items: center;
		
		color: #5A5A5A;
	}
	.Schedule_Meeting{
		font-weight: 500 !important;
		font-size: 20px !important;
		line-height: 30px;
			justify-self: center;
			justify-content: center;
			align-items: center;
			margin-top: 10px !important;
			
	}

	/* #innerweb{
		background-color:#F9FBFF ;
	}
 */



 
	.sidebar-list-contacts-outer img.avatar-contact {
		width: 45px;
		height: 46px;
		border-radius: 10px;
		margin-right: 20px;
		margin-top: 7px;
		margin-bottom: 9px;
	}

	.titleParticepent label{
	
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 15px !important;
	
		align-items: center;
		
		color: #5A5A5A;
	}





	.MuiFormGroup-root-1149 {
		display: flex;
		flex-wrap: nowrap !important;
		flex-direction: column;
	}

	.accordion-text label{
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 15px !important;
	
		align-items: center;
		
		color: #5A5A5A;
	}
	/* .accordion-text {
		font-size: 16px !important;
		font-weight: 500 !important;
		color: #5A5A5A;
	} */
	.recurring-toggle .label{
		font-weight: 500;
font-size: 14px  !important;
line-height: 21px;
	}
	.labels {
		color: #5A5A5A !important;
		font-size: 14px !important;
		font-weight: 500 !important;
	  }
	  .accord-labels {
		color: #5A5A5A;
		font-size: 14px !important;
		font-weight: 600 !important;
	}

	#exampleModalLongTitle {
		color: #4E78B8;
		font-weight: 500;
font-size: 14px;
line-height: 21px;
margin-bottom: 20px;
	}

	.nav-tabs .nav-link.active {
		background-color: inherit;
		border-bottom: 1px solid #1967d8 !important;
		color: #4E78B8 !important;
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		font-size: 12px;
	}

	.nav-tabs .nav-link {
		/* border: none !important; */
		background-color: inherit;
		border-bottom: 1px solid rgba(107, 140, 190, 0.3) !important;
		border-top: 0px !important;
		border-left: 0px !important;
		border-right: 0px !important;
		font-size: 12px;
	}
	.search-icon {
		left: 30px;
		position: absolute;
		top: 57px !important;
	/* 	top:89px; */
		color: #E0E1E2;
	}
	.by-contacts {
		padding: 1.25rem 2.5rem !important;
		/* border-radius: 10px !important; */
	
		border: 1px solid #E0E1E2;
		border-radius: 4px !important;

	}

	.fname{
		font-size: 14px !important;
	}
	.email-font{
		font-size: 12px !important;
	}
	.passwrd{
		width: 123px  !important;
	}
	.discardModal-text {
		font-size: 14px !important;
		font-weight: 500;
		color: #3B414E;
	}
	.discard-word{
		font-size: 14px !important;
	}
	.modal-body {
		position: relative !important;
		flex: 1 1 auto;
		padding: 13px !important;
		/* top: -23px !important; */
	}


	.input-arrange input,
.input-arrange select {
	height: 41px;
	font-size: 14px;
	line-height: 21px;
	color: #3B414E;
	border: 1px solid #ECECEC;
	border-radius: 10px !important;
}



	.contactsheading{
	  font-size: 20px !important;
	  margin-top: 0px !important;
	}
	.view-contact-info>p {
	
		font-size: 11px !important;
		margin-top: 9px;
		margin-bottom: 29px; 
  }

  .view-contact-infopanel>p {
	
	font-size: 11px !important;
	margin-top: 9px;
	margin-bottom: 29px; 
}

 
  .by-contacts-book {
	/* padding: 1.5rem 0.5rem 1.5rem 1.25rem !important; */
	border-radius: 10px !important;
	border-color: #E0E1E2;
	/* position: absolute; */
/* 	 width: 280px !important;  */
	height: 38px !important;
	/* left: 30px; */

	/* background: #FFFFFF; */
	border: 1px solid #ECECEC;
	border-radius: 8px;
  }

  .by-contacts-book-main{
		/* padding: 1.5rem 0.5rem 1.5rem 1.25rem !important; */
		border-radius: 10px !important;
		border-color: #E0E1E2;
		/* position: absolute; */
		/*  width: 280px !important;  */
		height: 38px !important;
		/* left: 30px; */
	
		/* background: #FFFFFF; */
		border: 1px solid #ECECEC;
		border-radius: 8px;
  }
  .search-icon-contact {
	right: 6px !important;
	position: absolute;
	top: 14px !important;
	color: #E0E1E2;
  }
  .Contact-Sidebar .contact-accordions p {
    color: #3B414E;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 27px;
}

.headder {
    font-weight: 500;
    font-size: 14px ;
    line-height: 20px;
    margin-bottom: 0px;
}

/* .subheadder {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px;
} */

.accordion-title{

  /*   height: 60px !important; */
    padding: 7px 12px 0px !important;
    background-color: #F9FBFF;
    height: 70px; 
/*     width: 354px; */
    left: 20px;
    top: 378px;
    border-radius: 0px;
}
.Mui-expanded .contact-accordions {
	height: 46px !important;
	
}	
}

.Schedule_closeBtn {
	float: right;
	margin-top: 0px;
	margin-left: 12.5%;
	background-color: transparent;
	border: none;
	position: absolute;
	color: var(--block);
	text-decoration: underline;
}

.Schedule_closeBtn-edit-page {
	float: right;
	margin-top: 0px;
	margin-left: 5.5%;
	background-color: transparent;
	border: none;
	position: absolute;
	color: var(--block);
	text-decoration: underline;
}

@media only screen and (max-width: 1280px) and (min-width: 962px) {
	.Schedule_closeBtn {
		margin-left: 8.5%;
	}

	.Schedule_closeBtn-edit-page {
		margin-left: 8.5%;
	}
}

@media only screen and (max-width: 961px) and (min-width: 600px) {
	

	.Schedule_closeBtn {
		margin-left: 6.5%;
	}

	.Schedule_closeBtn-edit-page {
		margin-left: 6.5%;
	}

	.payment-history-parent {
		width: 90% !important;
	}

	.payment-history {
		width: 100% !important;
	}
}

@media only screen and (max-width: 599px) and (min-width: 280px) {
	
	
	.payment-history-parent {
		width: 90% !important;
		overflow: auto;
	}

	.CustomPopUp .MuiPaper-root-851,
	.CustomPopUp.Participants_List .MuiPaper-root-851,
	.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
		width: 400px !important;
	}

	.ellipsisMoreParticipants{
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100px  !important;
		display: block;
		overflow: hidden;
		font-size: 12px;
	/* 	margin:0px -108px 0px 3px; */
	}

.Notify{
width:14.079999923706055px;
height: 18px;
}
.AssignCo-Host{
width:14.079999923706055px;
height: 18px;
}

.Remove{
width:14.079999923706055px;
height: 18px;
}
.tableimage{
width:39.08px;
height: 56px;

}
.tableimage-inner{
width:14.079999923706055px;
height: 18px;
}

.header-sizes {
	display: flex !important;
	/* width: 320px; */
	font-size: 10px;
	margin-left: 12px;
	margin-top: 18px;
}


p.meetingfooterpara {
	margin-bottom: 13px;
background: #EEF2FF;
border-radius: 5px;
color: #4E78B8 !important;
    padding: 5%;
    text-align: center;
}





.CustomPopUp.Meeting_Participants .table th {
	width: 60px;
	padding: 5px 0px 5px 15px;
	margin-left: auto;
	margin-right: auto;
}


.action_buttons{
	display: inline-block !important;
}
}

@media only screen and (max-width: 599px) and (min-width: 499px) {
	
	
	.Schedule_closeBtn {
		margin-left: 30.5%;
	}

	.Schedule_closeBtn-edit-page {
		margin-left: 35.5%;
	}
}

@media only screen and (max-width: 498px) and (min-width: 350px) {

	.Schedule_closeBtn {
		margin-left: 20.5%;
	}

	.Schedule_closeBtn-edit-page {
		margin-left: 20.5%;
	}

	.CustomPopUp .MuiPaper-root-851,
	.CustomPopUp.Participants_List .MuiPaper-root-851,
	.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
		width: 340px !important;
	}
}

@media only screen and (max-width: 349px) and (min-width: 280px) {
	

	.Schedule_closeBtn {
		margin-left: 10.5%;
	}

	.Schedule_closeBtn-edit-page {
		margin-left: 30.5%;
	}

	.CustomPopUp .MuiPaper-root-851,
	.CustomPopUp.Participants_List .MuiPaper-root-851,
	.CustomPopUp.Meeting_Participants .MuiPaper-root-851 {
		width: 300px !important;
	}
}

.Meeting_detail {
	color: var(--block) !important;
	margin: 20px 0px 0px 0px;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 21.60px !important;
}

.Meeting_heading {
	color: var(--block) !important;

	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 21.60px !important;
}

.Meeting_join_metting {
	color: var(--block) !important;
	padding-bottom: 20px !important;
	font-size: 17px !important;
	font-weight: 400 !important;
	line-height: 21.60px !important;
}

.Meeting_heading_host {
	color: var(--block) !important;
	padding-top: 20px !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 21.60px !important;
}

.text-in-metting {
	color: var(--color-blue) !important;
}

.metting-down-section {
	color: var(--block) !important;
	padding-top: 20px !important;
	padding-bottom: 10px !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	margin-left: 15px !important;
	line-height: 21.60px !important;
}

.Inpu-Email-Address {
	border-radius: 8px !important;
	border-color: var(--light-blue) !important;
}

.Meeting-last-button {
	background-color: var(--color-blue) !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	/* line-height: 19.12px !important;  */
	padding: 10px 10px 10px 0px !important;
	color: var(--white) !important;
	text-transform: capitalize !important;
	border-radius: 8px !important;

}

@media only screen and (max-width: 600px) and (min-width: 280px) {
	
	
	.Meeting-last-button {
		background-color: var(--color-blue) !important;
		font-size: 12px !important;
		font-weight: 500 !important;
		/* line-height: 19.12px !important;  */
		padding: 10px 20px 10px 20px !important;
		color: var(--white) !important;
		text-transform: capitalize !important;
		border-radius: 8px !important;

	}

	.cancelBtn {
		background: transparent;
		border: 1px solid var(--color-blue) !important;
		font-size: 12px !important;
		color: var(--color-blue) !important;
		/* text-decoration: underline !important; */
		margin-top: 8px;
		text-transform: capitalize !important;
		width: 100% !important;
		padding: 10px 20px 10px 20px !important;
		border-radius: 8px !important;


	}

	.meetingtabs .sidebar2 button.previous,
	.meetingtabs .sidebar2 button.Upcoming {
		width: 100%;
	}
	

}


@media print {
	.no-print {
		display: none;
	}
}

.logohead {
/* 	position: absolute; */
	width: 199.60px;
	left: 38px;
	height: 44px;
 
}

@media only screen and (max-width: 599px) and (min-width: 280px) {
	

	.logohead {
		position: relative;
		width: 199.60px;
		left: 27px;
		height: 44px;

	}
}

.position_inner {
	display: flex;
}

@media only screen and (max-width: 990px) and (min-width: 280px) {
	.position_inner {
		display: flex;
		flex-direction: column;
	}
}

@media only screen and (max-width: 990px) and (min-width: 280px) {
	
	
	.add_host_popup {
		width: "95% !important";
	}

	.closeBtn_add_host_popup {
		float: right;
		margin-top: -70px;
		margin-right: 0px;
		background-color: transparent;
		border: none;
		color: var(--block);
		outline: none;
	}

	/* width: "62%", */
}

@media only screen and (max-width: 2400px) and (min-width: 991px) {
	

	.add_host_popup {
		width: "45% !important";
	}

	.closeBtn_add_host_popup {
		float: right;
		margin-top: -70px;
		margin-right: -25px;
		background-color: transparent;
		border: none;
		color: var(--block);
		outline: none;
	}
}

body {
	overflow-y: auto;
}

.cust-scroll {
	padding-top: 60px;
	background-color: #F9FBFF;
}


.editprofile{
	padding-top: 0px;
	background-color: #F9FBFF;
}
.titleParticepent {
/* 	display: flex; */
font-weight: 700;
}

.descripitionArea {
	margin-left: 10px;
}

@media screen and (max-width:959px) {
	.titleParticepent {
	/* 	display: block; */
	font-weight: 700;
	}

	.cust-scroll {
		padding-top: 0px;

	}

	.descripitionArea {
		margin-left: 0px;
	}
}

.dateFiled {
	border-radius: 10px;
}

.ck.ck-editor__editable_inline {

	min-height: 150px;
	padding: 5px 15px !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;

}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #f8f9fa !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
}

.ck.ck-toolbar {
	border: none !important;
}

.chk-height {
	height: 60px;
	padding-left: 1rem;
}

.main-accord {
	display: inline-flex !important;
}

.days-row {
	margin-top: 7px;
}

.repeat-on {
	margin-bottom: 0;
	margin-top: 8px;
	margin-right: 10px;
	font-weight: 700 !important;
}

.days-avatar {
	width: 30px !important;
	height: 30px !important;
	color: #4E78B8 !important;
	background-color: #E5E5E5 !important;
	font-size: 14px !important;
}

.Select-day {
	width: 30px !important;
	height: 30px !important;
	background-color: #4E78B8 !important;
	font-size: 14px !important;
}

.add-participent-mail-card {
	padding: 0px 16px 16px 16px;
}

.mail-card {
	height: 50px !important;
	padding: 5px 15px;
}

.email-font {
	font-size: 14px;
}

.plus-icon {
	margin-top: 10px;
	color: #4E78B8;
}

.fluid {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.editfluid{
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.labels {
	color: #5A5A5A !important;
	font-size: 15px;
	font-weight: 500 !important;

}

.participants-filed {
	margin-top: -9px;
	/* padding-left: 15px; */
	padding-left: 0px;
}

.cross-chip:hover {
	cursor: pointer;
}

.ck.ck-editor {
	border: 1px solid #ECECEC !important;
	border-radius: 10px !important;
}

.ck.ck-editor__editable_inline:focus+.ck.ck-editor {
	border-color: red !important;
}

.modal-head {
	border-bottom: none !important;
}

.nav-tabs {
	border: none !important;
	padding-bottom: 10px;
}

.nav-link {
	padding: 1rem 3.5rem ;
	font-size: 16px;
	font-weight: 500;
	color: #4E78B84D !important;
}

.nav-tabs .nav-link.active {
	background-color: inherit;
	border-bottom: 1px solid #1967d8 !important;
	color: #4E78B8 !important;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
}

.nav-tabs .nav-link {
	/* border: none !important; */
	background-color: inherit;
	border-bottom: 1px solid rgba(107, 140, 190, 0.3) !important;
	border-top: 0px !important;
	border-left: 0px !important;
	border-right: 0px !important;

}

.by-contacts {
	padding: 1.25rem 2.5rem !important;
	border-radius: 10px ;
	border-color: #E0E1E2;
}

.by-contacts::placeholder {
	color: #E0E1E2 !important;
	font-size: 14px !important;
}

.by-contacts-book {
	padding: 1.5rem 2.5rem 1.5rem 1.25rem !important;
	border-radius: 10px !important;
	border-color: #E0E1E2;
}

.by-contacts-book-main{
	padding: 1.5rem 2.5rem 1.5rem 1.25rem !important;
	border-radius: 10px !important;
	border-color: #E0E1E2;
}

.by-contacts-book::placeholder {
	color: #E0E1E2 !important;
	font-size: 14px !important;
}

.by-contacts-book-main::placeholder {
	color: #E0E1E2 !important;
	font-size: 14px !important;
}

.Contact-Sidebar .contact-accordions {
	background: #F9FBFF !important;
	padding: 1px 15px 0px 0px !important;
}

.Contact-Sidebar svg,
.AddGroupModal svg {
	color: #4E78B8;
}

.Contact-Sidebar .contact-accordions p {
	color: #3B414E;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
}

.numbering-outer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.numbering-inner {
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #D6DDE7;
	border-radius: 10px;
	padding: 6px 12px;
	color: #4E78B8;
}

.Mui-expanded .contact-accordions {
	background:#E3EDFCCC !important;
	

	border-radius: 8px !important;
}

.Contact-Sidebar .css-ta84vm-MuiAccordionDetails-root:first-child {
	border-top: 0px !important;
}

.sidebar-list-contacts-outer {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.sidebar-list-contacts-outer img.avatar-contact {
	width: 45px;
	height: 45px;
	border-radius: 10px;
	margin-right: 20px;
    margin-left: 10px;
}

.sidebar-list-contacts-outer img {
	width: 18px;
	height: 18px;
}

.sidebar-list-contacts-outer .plus-minus {
	margin-left: auto;
}

.sidebar-list-contacts-outer{
	font-weight: 500;
	font-size: 17px;
	line-height: 26px;
	color: #3B414E;
	margin-bottom: 0px;
}

 .subheadder{
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	width: 86%;
	overflow: hidden;
	text-overflow: ellipsis; 
	height: 40px;
	white-space: nowrap;
}

.headder{
font-weight: 500;
font-size: 17px;
line-height: 20px;
width: 85%;
margin-bottom: 0px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.sidebar-list-contacts-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 550px;
}
.sidebar-list-contacts-inner img {
	margin-left: 20px;
}

.search-icon {
	left: 30px;
	position: absolute;
	top: 145px;
/* 	top:89px; */
	color: #E0E1E2;
}

.search-icon-contact {
	right: 15px;
	position: absolute;
	/* top: -20px !important; */
	color: #E0E1E2;
	z-index: 100;
}

.back-icon-contact {
	left: -30px;
	position: absolute;
	top: 15px;
	color: #E0E1E2;
}


.contact-list {
	max-height: 250px;
	overflow: auto;
}

.contact-list::-webkit-scrollbar {
	width: 6px;
	margin-left: 5px;
}

.contact-list::-webkit-scrollbar-thumb {
	background-color: #4E78B8;
	border-radius: 10px;
}

.contacts-heading {
	font-size: 18px;
	color: #3B414E;
}

#exampleModalLongTitle {
	color: #4E78B8;

}

.cancel-btn {
	background-color: #E5E5E5 !important;
    color: #3B414E !important;
    border-color: #E5E5E5 !important;
	padding: 7px 15px;
    /* border-radius: 10px !important; */
   /*  max-width: 86px !important; */
}

.confirm-btn {
	background-color: #4E78B8 !important;
	border-color: #74A6EF !important;
	padding: 0.45rem 0.6rem;
	border: 0px;
	border-radius: 5px;
	color: #fff;
}

.advance-accordions {
	background-color: #F4F6FC !important;
	border-radius: 10px !important;
}

.accord-labels {
	color: #5A5A5A;
	font-size: 16px ;
	font-weight: 600 !important;
}

.info-btn {
	min-width: 40px !important;
}

.fw-600 {
	font-weight: 600;
}

.bt-cust {
	border-top: 1px solid rgba(177, 193, 215, 0.26);
}

.TextField-with-border-radius fieldset {
	border-radius: 10px;
}

.meetingControl-heading1 {
	font-size: 15px !important;
	font-weight: 600 !important;
	color: #5A5A5A;
}


.meeting-banner {
	width: 270px;
	height: 90px;
	border: 1px solid #DDE3F3;
	border-radius: 20px;
}

.meetingBanner-imageDescr {
	width: 306px;
	margin-left: 30px !important;
	font-size: 12px;
	color: #5A5A5A;
}

.textOver-image {
	position: absolute;
	bottom: 30px;
	/* left: 120px; */
	margin-left: 15px;
}

.meeting-dateTime {
	font-size: 13px;
	color: #6F7B8D;
}

.title-meeting {
	font-size: 18px;
	color: #6F7B8D;
}

.vectorEdit {
	position: absolute;
	bottom: 26px;
	margin-right: 109px;
	cursor: pointer;
}

.discardModal-header {
	border-bottom: 0px !important;
	padding: 0.5rem 1rem 0 1rem !important;
}

.discardModal-footer {
	border-top: 0px !important;
	padding: 0 0.75rem 10px 0.75rem !important;
}

.discardModal-text {
	font-size: 16px;
	font-weight: 500;
	color: #3B414E;
}

.discard-word {
	font-size: 16px;
	font-weight: 600;
	color: #3B414E;
}

.data-body {
	padding: 1rem 1rem 0 1rem !important;
}

.btn-cancel {
	background-color: #E5E5E5 !important;
	border-color: #E5E5E5 !important;
	color: #3B414E !important;
	padding: 0.45rem 0.6rem;
	border: 0px;
	border-radius: 5px;
}

.btn-send {
	background-color: #4E78B8 !important;
	border-color: #4E78B8 !important;
	border-radius: 5px;
	padding: 0.45rem 0.6rem;
	border: 0px;
	border-radius: 5px;
}

.btn-discard {
	background-color: #E05047 !important;
	border-color: #E05047 !important;
	border-radius: 5px;
	padding: 0.45rem 0.6rem;
	border: 0px;
	border-radius: 5px;
}

.modal-content.modal-data {
	height: 170px !important;
	width: 90% !important;
	border-radius: 20px !important;
}

.modal-content.ConfirmModal-data {
	width: 90% !important;
	border-radius: 20px !important;
}

.confirm-textarea {
	background-color: #F4F6FC !important;
	border-radius: 10px !important;
	padding: 1rem 0.75rem !important;
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
	margin-bottom: 16px;
}

/* .participiants-addByContact{
	padding-left: 1.5rem;
}
.infoVector{
	width: 20px;
	height: 20px;
}
/* .MuiTooltip-tooltip::before{
	content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
  
    border-top: 20px solid transparent;
    border-bottom: 8px solid transparent; 
    border-right:8px solid #FFFF;
    left:0px;

    top:2px;
} */
/* .MuiPaper-elevation1-4090{
	box-shadow: none !important;
} */
.paginationss {
	display: inline-block;
}

/*.paginationss span {
    float: left;
    text-decoration: none;
    transition: background-color .3s;
    border-radius: 20px;
    color: #4E78B8;
    background-color: #E5E5E5;
    font-size: 14px;
    min-width: 38px;
    height: 38px;
    padding: 9px 0px;
    text-align: center;
}
.paginationss span.active {
	color: #fafafa;
	background: #4E78B8;
	border-radius: 20px;
}
 .paginations span:hover:not(.active) {
	background-color: transparent;
	color: #002964;
} 
.paginationss span:hover{
	text-decoration: none;
}*/
.radio_container__ {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: transparent;
	width: 280px;
	border-radius: 9999px;
	box-shadow: none;
}

.paginationss input[class="radio-appearence-hide"] {
	appearance: none;
	display: none;
}

.days-labels {
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 30px;
	height: 30px;
	text-align: center;
	overflow: hidden;
	transition: linear 0.3s;
	color: #4E78B8;
	background: #E5E5E5;
	border-radius: 100px;
	margin-left: 5px;
}

.paginationss input[class="radio-appearence-hide"]:checked+label {
	background-color: #1e90ff;
	color: #f1f3f5;
	font-weight: 900;
	transition: 0.3s;
}

.advance-accordions:before {
	top: 0px !important;
	height: 0px !important;
}

.advance-accordions svg {
	color: #4E78B8;
}

.ck.ck-editor svg,
.displayFromTo svg {
	color: #4E78B8;
}

.displayFromTo svg {
	color: #4E78B8;
	margin-right: 15px;
}

.displayFromTo input[type="time"]::-webkit-calendar-picker-indicator,
.marginpopup.marginmeetingschedule input[type="time"]::-webkit-calendar-picker-indicator {
	filter: invert(70%) sepia(45%) saturate(202%) hue-rotate(177deg) brightness(88%) contrast(85%);
	transform: scale(1.3);
}

.marginpopup.marginmeetingschedule fieldset {
	border-radius: 10px !important;
	border-color: #ECECEC !important;
}

.timezonesvg svg {
	margin-right: 10px;
	color: #C8CFD9;
}

.recurring-toggle .switch,
.advance-accordions .switch {
	position: relative;
	display: inline-block;
	width: 46px;
	height: 20px;
	margin-bottom: 0px !important;
	margin-left: 15px !important;
	margin-right: 15px !important;
}

.titleParticepent input::placeholder,
.ck .ck-placeholder:before {
	font-weight: 400 !important;
	font-size: 14px !important;
	color: #B4B4B4 !important;
	font-style: normal !important;
}

/* .recurring-toggle .switch{
	margin-left: initial;
	margin-right: 15px;
} */
.recurring-toggle .switch input,
.advance-accordions .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.recurring-toggle .slider,
.advance-accordions .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #C8CFD9;
	-webkit-transition: .4s;
	transition: .4s;
}

.recurring-toggle .slider:before,
.advance-accordions .slider:before {
	position: absolute;
	content: "";
	height: 17px;
	width: 17px;
	left: 1px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.recurring-toggle input:checked+.slider,
.advance-accordions input:checked+.slider {
	background-color: #4E78B8;
}

.recurring-toggle input:focus+.slider,
.advance-accordions input:focus+.slider {
	box-shadow: 0 0 1px #4E78B8;
}

.recurring-toggle input:checked+.slider:before,
.advance-accordions input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.add-by-contact-btn {
	cursor: pointer;
}

/* Rounded sliders */
.recurring-toggle .slider.round,
.advance-accordions .slider.round {
	border-radius: 34px;
}

.recurring-toggle .slider.round:before,
.advance-accordions .slider.round:before {
	border-radius: 50%;
}

.recurring-toggle label {
	flex-direction: row-reverse;
	margin-left: 10px;
}

.marginpopup #demo-customized-select {
	padding: 15px;
}

.login-button.padding button {
	padding: 0px;
}

.titleParticepent label {
	margin-bottom: 15px !important;
}

.allowusers {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

span.update-text {
	position: absolute;
	top: 206px;
	font-size: 10px;
	background: #ECECEC;
	border-radius: 50px
}

span.ck-file-dialog-button {
	display: none !important;
}

@media screen and (max-width:1279px) and (min-width:960px) {
	.custom-width {
		width: 100%;
	}

	.descripitionArea {
		margin-left: 0;
	}
}



@media screen and (max-width:992px) {
	.accordion-text label{
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 15px !important;
	
		align-items: center;
		
		color: #5A5A5A;
	}
	
	.participants-filed {
		padding-left: 0;
	}

	.participiants-addByContact {
		padding-left: 0;
	}

	.chk-height {
		padding-left: 0;
	}

	.ck.ck-reset.ck-editor.ck-rounded-corners {
		margin-bottom: 25px;
	}
}

@media screen and (max-width:613px) {
	.custome-btn {

		padding: 8px 27px !important;
		font-size: 16px !important;

		margin-top: 14px !important;
		text-align: center;
    margin-top: 14px !important;
	}

	.fluid {
		padding-left: 18px !important;
		padding-right: 18px !important;
	}


	.editfluid{
		padding-left: 18px !important;
		padding-right: 18px !important;
	}

	.profilefluid{
		padding-left: 2px !important;
		padding-right: 2px !important;
	}

	.duration_time{

		display:block !important

	}
}


.duration_time{

	display:flex;

}

/*shree*/
.table-responsive .table {
    width: -webkit-fill-available;
}
.vl.vr {
    height: fit-content;
}
span.custom-color {
    color: #12376d;
    font-size: 16px;
    font-weight: 700;
}
.order-details{
	color: #12376d;
	font-weight: normal;
}
a:hover {
    color: #4E78B8 !important;
    text-decoration: none !important;
}
a{
    color: #4E78B8 !important;
    text-decoration: none !important;
}

.cardheader{
	
	padding: 0.75rem 0.25rem !important;
		margin-bottom: 0;
		background-color: none !important;
		border-bottom: 1px solid rgba(0,0,0,.125);
	}


	.usercard{
		margin-right: -0.5rem!important;
	}
	.css-ja5taz-MuiTooltip-tooltip {
		background-color:#4E78B8 !important;
		border-radius: 4px;
		color: #fff;
	
		padding: 4px 8px;
		font-size: 0.6875rem;
		max-width: 300px;
		margin: 2px;
		word-wrap: break-word;
		font-weight: 500;
	}
.calendar{
	
    height: 44px;
    background: #F4F6FC;
    border-radius: 5px;
    border: none;
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
margin-top: 8px;
align-items: center;


color: #000000 !important;
}
.cross-chip1{
	margin-top: -37px;
    cursor: pointer;
    position: relative !important;
}
.cross-chip{
	margin-top: -37px;
    cursor: pointer;
    position: relative !important;
}

.cross-chip3{
	margin-top: -35px;
    cursor: pointer;
    position: relative !important;
}

.meetingScheduleContactDropdown{
	height: 184px;
	overflow: auto;
}

.schdeuleMeetingScrollBar{
	height: 91vh;
    overflow: auto;
}

#profile-form{
	margin-top: 20px !important;
  }
  .ellipsisProfilePage{
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 160px;
	display: block;
	overflow: hidden
  }

  .ellipsisMoreParticipants{
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 200px;
	display: block;
	overflow: hidden
  }

  .addparticipantsMeetingpagepopup{
	white-space: nowrap;
	text-overflow: ellipsis;
/* 	width: 320px; */
	display: block;
	overflow: hidden
  }
 
  .addparticipantsMeetingpage{
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 180px;
	display: block;
	overflow: hidden
  }
  .ellipsisProfilePage1{
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 10px;
	display: block;
	overflow: hidden
  }
  .widthInSchdeuleMeetingChip{
	  width: 210px;
  }


  .notfound{
	color: red !important;
}



@media (max-width: 767px) {
	.MdOutlineClose {
		margin-top: -64px;
    margin-bottom: 1rem;
    padding: 9px;
	margin-top: -4px;
	}
	.amount{
		font-size: 18px;
	}
	.verticalLine {
		height: 95px;
		border-right: 1px solid #dedede;
		position: absolute;
		right: 55%;
	}
	.order-details {
		color: #12376d;
		font-weight: 500;
		margin-top: 4px;
	}
	.addContacts {
		border: 1px solid #989898;
	/* 	background-color: var(--color-blue); */
		background-color: #4E78B8;
		color: #fff;
		border-radius: 10px;
		width: 100%;
		font-size: 14px;
		padding: 6px;
		outline: none;
	}
	.AddGroupMembers {
		padding-left: 22px !important;
		top: 3px !important;
		position: relative;
		margin-left: -13px;
	}
	.my-profile-text {
		font-size: 25px;
		font-weight: 600;
		line-height: 38px;
		color: #3b414e;
		margin-left: 10px !important;
	}

	
  }
  

  @media (max-width: 375px) {
	.MdOutlineClose {
		margin-top: -57px;
		margin-bottom: 1rem;
	}
}
  

  @media (min-width: 768px) and (max-width: 1024px) {
	.MdOutlineClose {
		margin-top: 4px;
		margin-bottom: 1rem;
	}
	.amount{
		font-size: 12px;
	}
	.verticalLine {
		height: 75px;
		border-right: 1px solid #dedede;
		position: absolute;
		right: 55%;
	}
	.order-details {
		color: #12376d;
		font-weight: 500;
		margin-top: 4px;
	}
	.addContacts {
		border: 1px solid #989898;
	/* 	background-color: var(--color-blue); */
		background-color: #4E78B8;
		color: #fff;
		border-radius: 10px;
		width: 100%;
		font-size: 14px;
		padding: 6px;
		outline: none;
	}
	.AddGroupMembers {
		padding-left: 22px !important;
		top: 6px !important;
		position: relative;
		margin-left: -13px;
		padding-top: 12px;
		padding-bottom: 7px;
	}
	#E-M-exampleInputEmail1 {
		padding-left: 10px !important;
		top: -35px !important;
		position: relative;
	}
	.my-profile-text {
		font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    color: #3b414e;
 
    margin-left: 0px !important;
	}
	
  }
  

  @media (min-width: 768px) and (max-width: 834px) {
	.MdOutlineClose {
		margin-top: -47px;
		margin-bottom: 1rem;
	}

	.Contact-Sidebar svg, .AddGroupModal svg {
		color: #4E78B8;
		/* position: absolute; */
		/* right: 33px; */
		/* top: 6px; */
		/* text-align: end; */
		/* margin-left: 111px; */
	}
	.amount{
		font-size: 18px;
	}
	.verticalLine {
		height: 75px;
		border-right: 1px solid #dedede;
		position: absolute;
		right: 55%;
	}
	.AddGroupMembers {
		padding-left: 22px !important;
		top: 3px !important;
		position: relative;
		margin-left: -13px;
	}
	.my-profile-text {
		font-size: 25px;
		font-weight: 600;
		line-height: 38px;
		color: #3b414e;
		margin-left: 0px;
	}
  }
 
  
 
  @media (min-width: 1025px) {
	.MdOutlineClose {
		margin-top: 0px;
		margin-bottom: 1rem;
		
	}


	.invoice{
		font-size: 30px;
	}
	.amount{
		font-size: 18px;
	}
	.verticalLine {
		height: 75px;
		border-right: 1px solid #dedede;
		position: absolute;
		right: 36%;
	}
	.addContacts {
		border: 1px solid #989898;
	/* 	background-color: var(--color-blue); */
		background-color: #4E78B8;
		color: #fff;
		border-radius: 10px;
		width: 100%;
		font-size: 14px;
		padding: 6px;
		outline: none;
	}

	.AddGroupMembers {
		padding-left: 22px !important;
		top: 3px !important;
		position: relative;
		margin-left: -13px;
	}
	.my-profile-text {
		font-size: 25px;
		font-weight: 600;
		line-height: 38px;
		color: #3b414e;
		margin-left: 0px;
		
	}
	.main_container_profile{
		margin-top: 60px;
	}
	.invite-by-email-add {
		top: -85px;
		position: absolute;
		width: 85px;
		height: 49px;
		left: 308px !important;
		border-radius: 10px;
		background: #74A6EF !important;
	}
  }

/*   .verticalLine {
	height: 75px;
    border-right: 1px solid #dedede;
    position: absolute;
    right: 36%;
} */
 

.labelsfor{
	color: rgb(0 0 0 / 70%) !important;
    margin-top: 16px;

}


.checkbox-container {
	display: flex;

	gap: 16px;
  }
  
 
  @media (max-width: 768px) {
	.checkbox-container {
		display: inline-block;
	}
  }
  

  @media (max-width: 480px) {
	.checkbox-container {
	  gap: 8px;

	}
  }




  .modal-contentmeeting{
border-radius: 0px !important;
backdrop-filter: brightness(0.5);

  }

  .modal-headermeeting{
	 border-bottom: 0px solid #dee2e6 !important;
  }


  .modal-footermeeting{
	border-top: 0px solid #dee2e6 !important;
  }

 .custome-btn{
	padding: 7px 7px !important;
 }



 @media (min-width:769px) and (max-width:1229px){


	.all-meetings{
		margin-left: 56px !important;
	}
	.view-contact-info {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 40%;
		left: 1%!important;
		font-size: 18px;
	
	}

	.view-contact-infopanel>p {
	
		display: flex;
		justify-content: center;
		align-items: center;
	/* 	position: absolute; */
		top: 40%;
		left: 1%!important;
		font-size: 18px;
	}

}
.edit-button {
	margin-right: 10px; 
	border: 1px solid #dfe3e8;
	min-width: 24px;
    padding: 2px 8px;
    font-size: 16px;
    border-radius: 6px;
    line-height: 20px;
	color: #131619;
    background-color: #fff;
    border-color: #dfe3e8;
  }
  
  .delete-button {
	margin-left: 10px; 
	border: 1px solid #dfe3e8;
	min-width: 24px;
    padding: 2px 8px;
    font-size: 16px;
    border-radius: 6px;
    line-height: 20px;
	color: #131619;
    background-color: #fff;
    border-color: #dfe3e8;
  }

  .add-session{
	border: 1px solid #dfe3e8;
	min-width: 24px;
    padding: 8px 8px;
    font-size: 16px;
    border-radius: 6px;
    line-height: 20px;
	color: #131619;
    background-color: #fff;
    border-color: #dfe3e8;
  }

  @media (min-width: 1200px){

  }



  
  @media only screen and (max-width: 599px) and (min-width: 280px)
  {
	.dailogcontect-shedulehost {
		max-width: 600px !important;
	  }
	 
  }





  .meeting-scehdule-detail {
    height:100%;
    overflow-x: hidden;
  }
  
  
  
  @media (max-width: 991px)
{
.meeting-scehdule-detail {
    width: 100%;
    margin: 0 auto;
    /* padding-top: 20px; */
    position: relative;
    top: 0px !important;
    height: 100vh;
    overflow: auto;
    padding-bottom: 39% !important;
}
}



.meeting-scehdule-detail {
	width: 100%;
    margin: 0 auto;
    padding-top: 100px;
    position: relative;
/*     top: 32px; */

    overflow: auto;
    overflow-x: hidden;
 
	
}


@media (max-width: 1224px){
	.meeting-scehdule-detail {
/* 		height: 89vh !important; */
		overflow-y: auto;
	  }

	  
	.all-meetings{
		margin-left: 1px !important;
	}
}

.cust-scroll-meeting{
	padding-top: 2px;
	background-color: #F9FBFF;
	height: 100%;
}

@media (max-width:769px){


	.all-meetings{
		margin-left: 1px ;
	}
}


.all-meetings {
    margin-left: 56px ;
}


/* .custom-table-all-meeting th {
	background-color: transparent;
  }

  .custom-table-all-meeting{
	width: 100%;
	
	margin-top: 20px;
  }
  
  .custom-table-all-meeting th,
  .custom-table-all-meeting td {

	padding: 4px;
	text-align: center;
  } */
  
