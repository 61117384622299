.maximized-stream {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 10px 10px;
}
.maximized-stream .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-weight: bold;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    z-index: 999999;
    cursor: pointer;
}
.maximized-stream .user-stream-item .controls {
    right: 20px !important;
}

@media screen and (max-width: 600px) {
    .maximized-stream {
        padding: 10px;
    }
    .maximized-stream .popup-close {
        right: 10px;
    }
}