body.handling-call {
	overflow: hidden !important;
}
body.call-minimized .call-handler, body.call-minimized .call-popup-container {
	position: absolute;
	left: -1000px !important;
	width: 0 !important;
	height: 0 !important;
}
body.call-minimized .maximize-call-btn {
	visibility: visible;
}

body.call-minimized .maximize-hide-call-btn {
	/* 	visibility: visible; */
		display: none;
	}
.call-container, .call-handler {
	background: #2b2c30;	
}
.call-handler .fa,
.call-handler .far,
.call-handler .fas,
.call-handler .control,
.call-toast .fa,
.call-toast .fas {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 900;
}
.call-handler .control {
	color: white;
}
.call-parent {
	background: rgb(47, 49, 54);
}
.call-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
	height: 50px;
}
.call-header .right {
	display: flex;
    align-items: center;
    column-gap: 10px;
	margin-right: 25px;
    color: white;
    font-size: 21px;
	cursor: pointer;
}
.call-header .left {
	max-width: 50%;
	display: flex;
	align-items: center;
    padding: 15px 30px;
	background: #161616;
    border-radius: 0px 0px 25px 0px;
    color: #CACACA;
	cursor: pointer;
}
.call-container .right:hover, .call-container .left:hover, .call-container .top:hover {
	transform: none !important;
}
.call-title {
	font-size: 13px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.call-header .left i {
	margin-right: 25px;
}
.call-body {
    position: relative;
    width: 100%;
	height: calc(100% - 120px) !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
	padding: 10px;
}
.call-container .google-ad-container {
	height: 100%;
	margin-left: 10px;
}
.call-container .google-ad {
	width: 150px;
	height: 500px;
	background: #2f3136;
}
.call-container .google-ad-container .sponsored {
	color: white;
    font-size: 12px;
    margin-bottom: 3px;
}
.side-panel {
	width: 400px;
	height: calc(100% - 20px);
	position: absolute;
	top: 10px;
	right: 10px;
	background: white;
	z-index: 999999999;
	overflow-x: hidden;
	overflow-y: auto;
	border-radius: 10px;
	overflow: hidden;
	margin: 0 !important;
}
.side-panel-header {
	display: flex;
	width: 100%;
	height: 50px;
    color: #4E78B8;
    padding: 15px;
    font-weight: bold;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
	background: #F6F6F6;
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 0px;
	box-shadow: 2px 1px 2px 0px rgb(218 218 218);
}
.side-panel-header .actions {
	color: #4E78B8;
	background: none !important;
}
.side-panel-header .action {
	margin-left: 15px;
	cursor: pointer;
}
.side-panel-body {
	overflow-y: auto;
    height: calc(100% - 50px);
}
.side-panel-opened .call-body {
	padding-right: 430px;
}
.side-panel-opened .google-ad-container {
	display: none;
}
.close-side-panel {
	position: absolute;
	top: 2px;
	right: 2px;
	width: 20px;
	height: 20px;
	background: red;
	border-radius: 25px;
	color: white !important;
	text-align: center;
	z-index: 999999;
	cursor: pointer;
}
.close-side-panel:hover {
	background: red;
}
.call-container .dropdown-menu {
	width: auto !important;
	padding: 5px 0 !important;
    border-radius: 10px !important;
    box-shadow: 2px 1px 2px 0px rgb(0 0 0 / 10%);
	z-index: 9999999999;
}
.call-container .dropdown-menu .dropdown-item {
	width: 100% !important;
	min-width: max-content !important;
	padding: 7px 15px;
	color: #3B414E;
	font-size: 13px;
	text-align: left;
}
.call-container .dropdown-item .icon {
	display: inline-block;
	width: 30px;
	text-align: center;
	margin-right: 10px;
    font-size: 17px;
}
.call-container .dropdown-menu .dropdown-item:hover {
	text-decoration: none;
    background-color: #e9ecef !important;
}
.view-type .dropdown-toggle{
/* 	cursor: pointer;
	font-size: 14px;
	margin-right: -5px; */
	cursor: pointer;
    font-size: 14px;
    margin-right: -5px;
	margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 16px;
    /* position: absolute; */
    width: 158px;
    height: 44px;
    left: 0px;
    top: 0px;
    /* background: rgba(255, 255, 255, 0.25); */
    border-radius: 10px;

}
.view-type .dropdown-menu{
    right: 0;
   /*  left: auto !important; */
    top: 3px !important;
    padding: 5px 0px;
}
.view-type .dropdown-menu .dropdown-item {
	position: relative;
	display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    white-space: normal;
}
.view-type .dropdown-item .main-content {
	padding-left: 17px;
}
.view-type .dropdown-item .tick-mark {
	position: absolute;
	left: 10px;
}
.call-toast {
	background: #161616 !important;
	padding: 5px !important;
}
.call-toast.height-auto {
	min-height: auto !important;
}
.call-toast .icon {
	margin-right: 10px;
	font-size: 18px;
}
.call-toast.alert {
	background-color: #994949 !important;
}
.call-toast .toast-inner {
	display: flex;
	align-items: center;
}
.handling-call .Toastify__toast-container--top-center {
	width: max-content;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
}
.handling-call .Toastify__toast-container--bottom-left {
	bottom: 80px;
}
.call-notification {
	width: 350px;
    padding: 10px;
	position: absolute;
	margin: 0 auto;
    background: linear-gradient( 90deg, rgba(221, 227, 243, 0.1) 0%, rgba(221, 227, 243, 0.4) 100%);
    color: white;
    border-radius: 10px;
    z-index: 999999999;
}
.call-notification .icon {
	position: relative;
    font-size: 20px;
    margin-right: 10px;
    top: 2px;
}
.recording-notification {
	top: 60px;
	left: 10px;
    background-color: #2727a0;
}
.call-time-master {
	background-color: #eb3131 !important;
	top: 7px;
	left: 0;
	right: 0;
}
.call-time-master .time-container {
	display: inline-block;
}
.call-time-master .add-time-btn {
	position: relative;
    top: 2px;
    background: white;
    border-radius: 10px;
    color: black;
	float: right;
	font-size: 13px;
	border: none;
}
.call-time-master .time {
	font-weight: bold;
}
.call-container .modal {
	z-index: 99999999999 !important;
}
.call-container select {
	background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;
    padding: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #3B414E;
}
.popup-container {
	width: auto;
	border-radius: 15px !important;
	color: #3B414E;
	background: white;
}
.popup-container .popup-inner {
	padding: 15px 20px !important;
	border-radius: 15px !important;
}
.popup-container .popup-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.popup-container .popup-heading {
	color: #49638c;
	font-size: 20px;
}
.popup-container .popup-close {
	position: relative;
    top: -5px;
    right: -5px;
	cursor: pointer;
	font-weight: bold;
	color: #1C1B1F;
	z-index: 999;
}
.popup-container .popup-close .icon {
	font-size: 15px;
}
.popup-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}
.popup-container .options {
	text-align: right;
    margin-top: 20px;
}
.popup-container .options button {
	width: auto;
	min-width: auto;
	max-width: initial !important;
	height: auto !important;
	font-size: 14px;
    border-radius: 5px;
	margin-left: 5px;
	border: none;
	padding: 6px 20px;
	margin-right: 6px;
}
.popup-container .options button:first-child {
	margin-left: 0;
}
.popup-container .options button.success-btn {
	background: #4E78B8;
    color: white;
}
.popup-container .options .error-btn {
	background: #c26b6b;
    color: white;
}
.call-popup-container {
	position: fixed;
	width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 99999;
}
.call-handler .only-notification {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
	top: 0;
	left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
}
.call-handler .only-notification .top-side {
	display: flex;
	align-items: center;
}
.call-handler .only-notification p {
	margin-bottom: 0;
	text-align: center;
}
.call-handler .only-notification .icon {
    margin-right: 10px;
}
.call-handler .only-notification .go-back-btn {
	width: auto !important;
    background: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 5px 17px;
    font-size: 14px;
    margin-top: 20px;
}
.swal2-container {
	z-index: 99999999999;
}
.swal2-title {
	font-size: 15px !important;
	line-height: normal !important;
}
.swal2-html-container {
	font-size: 14px !important;
}
.swal2-actions {
	margin-top: 30px !important;
}
.swal2-confirm, .swal2-cancel {
	font-size: 13px !important;
}
.swal2-cancel {
	margin-right: 0 !important;
}
.leave-call-dropdown .dropdown-menu {
	top: -5px !important;
}
.call-container {
	width: 100%;
	height: 100%;
	position: relative;
}
.call-container input:focus {
	box-shadow: none !important;
}
.call-container .maximize-call-btn {
	visibility: hidden;
	position: fixed;
	top: 85px;
    right: 73px;
    width: 170px;
    height: 40px;
	display: flex;
    align-items: center;
    justify-content: center;
    background: #3dca5e;
	font-weight: bold;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 20%);
}
.call-container.full-screen.viewing-shared-screen .call-header {
	display: none;
}
.call-container.full-screen.viewing-shared-screen .call-body {
	height: calc(100% - 70px) !important;
}
.call-container .tooltip {
	margin-left: 0;
}
.shared-screen-layout .call-header {
	display: none;
}
.shared-screen-layout .call-footer {
	display: none;
}
.desktop-window-loading {
	display: flex !important;
	justify-content: center;
}
.desktop-window-loading .swal2-loading {
	margin-top: 0 !important;
}

@media screen and (max-width: 991px) {
	.popup-container {
		max-width: 70% !important;
		margin: 15px auto !important;
	}
}

@media screen and (max-width: 980px) {
	.side-panel {
		width: calc(100% - 40px);
	}
	.side-panel-opened .call-body {
		padding-right: 0;
	}
}

@media screen and (max-width: 767px) {
	.popup-container {
		max-width: 90% !important;
		min-width: 90% !important;
	}
	.popup-inner {
		padding: 10px 15px !important;
	}
	.popup-container .popup-header {
		margin-bottom: 15px;
	}
	.popup-container .popup-heading {
		font-size: 15px;
	}
	.countdown-visible .side-panel {
		top: 60px;
		height: calc(100% - 70px);
	}
	.side-panel-opened .call-time-master {
		width: calc(100% - 40px);
	}
	.call-time-master {
		top: 60px;
		left: auto;
		right: 20px;
		margin: 0;
	}
}

@media screen and (max-width: 600px) {
	.call-container .maximize-call-btn {
		width: 150px;
		height: 35px;
		top: 94px;
		right: 10px;
		font-size: 12px;
	}
	.call-header .left {
		padding-left: 10px;
	}
	.call-header .right {
		margin-right: 10px;
	}
}

@media screen and (max-width: 480px) {
	.handling-call .Toastify__toast-container--top-center {
		top: 55px;
	}
	.call-notification {
		width: 300px;
	}
}
