@import url('../../css/google_fonts_material_icons.css');
@import url('../../css/google_fonts_poppins.css');

.call-header {
    display: none;
}
.call-body {
    height: calc(100% - 70px) !important;
}
.nav-pills .nav-link.active {
    color: #fff!important;
    background: #4e78b8;
}
.nav-pills .nav-link {
    color: #adaeaf!important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
    padding: 10px!important;
}
.only-notification .go-back-btn {
    display: none;
}
.Toastify__toast-body {
    font-size: 14px;
}
.Toastify__toast-body a {
    text-decoration: underline !important;
    color: white !important;
}
.tooltip {
	visibility: hidden;
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 4px 8px 8px 4px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* left: 50%; */
	font-size: 12px;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    text-align: center !important;
	font-size: 12px !important;
}
.tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;

}
.con-tooltip:hover .tooltip{
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: .3s linear;
  animation: odsoky 1s ease-in-out infinite  alternate;

}
.action-icon-secondary {
    position: absolute;
    font-size: 11px !important;
    right: -8px;
    top: 24px;
}
.dropdown-actions .action-icon-secondary {
    display: none !important;
}
.footer_btns .action-btn .badge-icon {
    width: 17px;
    height: 17px;
    line-height: 17px;
}
.button {
    font-size: 14px;
}

@keyframes odsoky {
    0%{
        transform: translateY(6px);	
    }

    100%{
        transform: translateY(1px);	
    }
}