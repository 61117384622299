.user-streams-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-streams-container .pagination-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #3c3d40;
    color: #FFFFFF;
    border-radius: 30px;
    border: none;
}
.user-streams-container .pagination-btn i {
    position: relative;
    top: 1px;
}
.streams-container {
    display: flex;
    width: 100%;
    height: 100%;
}