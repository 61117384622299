.stream-item .reconnecting {
    position: absolute;
    top: 40px;
    right: 0px;
    color: white;
    background-color: #5d5d5d;
    padding: 0 3px;
}
.stream-item .overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    text-align: center;
    color: white;
    background: rgb(47, 49, 54);
    z-index: 999;
}
.stream-item .overlay-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
}