.recording-consent-modal .modal-content{
    padding: 20px;
    border: none;
}
.recording-consent-modal .modal-content h3{
    margin-bottom: 20px;
    font-size: 22px;
}
.recording-consent-modal .button{
    font-size: 14px;
}
.call-recorder-video {
    display: none;
    width: 500px;
    height: auto;
    position: absolute; 
    top: 0;
    left: 0;
    z-index: 999999999999;
}