.messages-list {
    padding: 20px;
    overflow-y: auto;
}
.message {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 30px;
}
.message-body {
    position: relative;
    display: inline-block;
    width: 250px;
    border-radius: 12px;
    line-height: 20px;
    padding: 14px;
    background: #F1F1F1;
    color: #3B414E;
    /* Yoonus Adding css for chat body */
    padding: 10px 15px 10px 15px;
    text-align: left;
    margin-right: 44px;   
}
/* Yoonus Adding css for chat body start */
.message.left .message-body ::before{
  
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #E3EDFC;
    border-right: 10px solid transparent;
    border-top: 10px solid #E3EDFC;
    border-bottom: 10px solid transparent;
    left: -16px;
    top: 0px;
    transform: rotate(-251deg);
}
.message.right .message-body ::after {
    content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #F1F1F1;
  border-right: 10px solid transparent;
  border-top: 10px solid #F1F1F1;
  border-bottom: 10px solid transparent;
  right: -12px;
  top: -4px;
  transform: rotate(-33deg);
  
}
/* Yoonus Adding css for chat body end */
.message img {
    width: 100%;
    height: 100%;
}
.message.right {
    text-align: right;
}
.message.left {
    text-align: left;
}
.message.left .message-body {
    width: 250px;
    position: relative;
    background: #E3EDFC;
}
.message .author-name {
    color: #4E78B8;
    font-size: 12px;
    font-weight: bold;
}
.message .time {
    font-size: 10px;
    color: #6B6B6B;
}
.message .private-info {
    font-size: 10px;
    color: #6c6c6c;
    text-decoration: underline;
    margin-bottom: 5px;
}
.message .profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}
.message .message-header .right-side, .message .message-header .left-side {
    display: inline-block;
}
.message .message-header .left-side {
    float: left;
}
.message .message-header .right-side {
    float: right;
}
.message.right .message-header .left-side {
    float: right;
    margin-right: 0;
}
.message.right .message-header .right-side {
    float: left;
    margin-left: 30px;
}
.no-messages{
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: #b3b3b3;
    font-style: italic;
}
/* Yoonus CSS start  */
.chatArrowIcon{
    margin-left: 100%;
    padding: 3%;
    margin-top: -17%;
    background: red;

}
.chatSenderName{
    display: flex;
    justify-content: end;
    align-items: center;
}
.chatNameMargin{
    margin-right: auto;
    margin-left: 19%;
}

.myChatTimeMargin{
margin-right: 18px;
}

.message-body-margin{
 margin-left: 50px;   
}
.chatFontSize{
    font-size: 15px;
}
.myChatTimeMarginActions{
    position: relative;
    left: -27%;
}
.privateText{
    color: #EE8983;
}
.marginHostUserName{
    margin-left: 38%;
    margin-right: 46%;
}

/* Yoonus CSS end  */