.reactions-list {
    position: fixed;
    bottom: 70px;
    left: 0;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 60px;
    background: #454545;
    border-radius: 50px;
    box-shadow: 1px 1px 0px 0px rgb(31 30 30);
    z-index: 9999999999;
}
.reactions-list.hidden {
    visibility: hidden;
}
.reaction-item {
    display: inline-block;
    width: 50px;
    text-align: center;
    cursor: pointer;
}
.reaction-item:hover .reaction-icon {
    font-size: 36px;
}
.reaction-item:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.reaction-item:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.reaction-icon {
    color: #edab2f;
    transition: 0.2s;
    display: inline-flex;
}
.reaction-icon.love {
    color: #ce3131;
}
.reactions-container .reaction-icon {
    font-size: 26px;
    text-shadow: 0 2px #363636;
}
.user-reaction-item {
    position: fixed;
    left: 70px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    z-index: 2147483647;
    animation: bottomToTopFade 5s;
    animation-iteration-count: infinite;
}
.user-reaction-item .reaction-icon {
    font-size: 60px;
    display: inline-flex;
}
.user-reaction-item .username {
    margin-top: 8px;
    font-size: 12px;
    background: #040404;
    border-radius: 50px;
    padding: 2px 10px;
    color: white;
    width: fit-content;
    max-width: 100px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}

@keyframes bottomToTopFade {
    from {
        opacity: 1;
    }

    to { 
        opacity: 0.6; 
        bottom: 60%; 
    }
}