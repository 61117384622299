.call-time-master .add-time-btn {
    margin: 0 !important;
    margin-left: 5px !important;
    margin-top: -2px !important;
    padding: 2px 10px !important;
    font-size: 13px;
}
.call-time-master .go-back-btn {
    margin-top: 0;
    padding: 2px 9px;
    font-size: 13px;
    float: right;
}