.speak-requests-container {
    padding: 10px;
}
.speak-requests-container .no-record {
    font-size: 14px;
}
.speak-requests-container .left-side {
    width: 40%;
}
.speak-requests-container .right-side {
    width: 60%;
}
.speak-requests-container button {
    font-size: 14px;
}