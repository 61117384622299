html,
body {
  flex-grow: 1;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;
  margin: 0;
  color: #0d0d0d !important;
  /* overflow: hidden; */
  /* overflow: hidden; */
  /* background-image: url('./../images/chat-wallpaper.png'); */
  /* background-image: url(''); */
  background-color: #fff !important;
}


@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: "Nastaleeq";
  src: local("Nastaleeq"),
    url(./../fonts/Nastaleeq//Nastaleeq.ttf) format("truetype");
  unicode-range: "U+0600-U+06FF, U+0750-U+077F, U+FB50-U+FDFF, U+FE70-U+FEFF";
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("Roboto"),
    url(./../fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"),
    url(./../fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: local("Roboto"),
    url(./../fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
  src: local("Roboto"),
    url(./../fonts/Roboto/Roboto-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 300;
  src: local("Amiri"),
    url(./../fonts/Amiri/Amiri-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 400;
  src: local("Amiri"),
    url(./../fonts/Amiri/Amiri-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 500;
  src: local("Amiri"),
    url(./../fonts/Amiri/Amiri-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 700;
  src: local("Amiri"), url(./../fonts/Amiri/Amiri-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 700;
  src: local("Amiri"),
    url(./../fonts/Amiri/Amiri-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Amiri";
  font-weight: 300;
  font-style: italic;
  src: local("Amiri"), url(./../fonts/Amiri/Amiri-Italic.ttf) format("truetype");
}

html[lang|="ur"] * {
  /* font-family: "Nastaleeq" !important; */
  font-family: "Poppins", sans-serif !important;
}

html:lang(ar) * {
  /* font-family: "Amiri" !important; */
  font-family: "Poppins", sans-serif !important;
}

html:lang(en) * {
  font-family: "Roboto" ;
  font-family: "Poppins", sans-serif !important;
}

.container-fluid {
  height: 100%;
}

.form-label {
  font-size: 0.8rem;
}

.MuiInputLabel-root {
  font-size: 0.8rem;
}

.form-control.form-control-lg {
  font-size: 0.5rem;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}

.form-control,
.form-check-label {
  font-weight: 300;
}

.form-outline .form-control.form-control-lg ~ .form-label {
  padding-top: 1.05rem;
  font-weight: 300;
  font-size: 0.9rem;
}

.form-check-label {
  color: #495057;
  font-size: 0.8rem;
  vertical-align: middle;
}

.card-login {
  padding-inline-start: 6rem;
  padding-inline-end: 6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  box-shadow: 0px 2px 3px #d0d0d0;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 10px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  font-weight: 300;
}

.form-outline .form-control:focus ~ .form-label,
.form-outline .form-control:valid ~ .form-label {
  color: #616fc2;
  padding-top: 0.7rem;
}

.form-outline.form-outline:focus {
  border: 1px solid #616fc2;
}

.colored-link,
a.colored-link:hover {
  color: #566fc5;
  font-weight: 350;
  font-size: 0.8rem;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.custom-button {
  background-color: #0cb36d;
  color: #fff;
}

.custom-button:hover {
  color: #fff;
}

.form-check-input[type="checkbox"] {
  margin-inline-start: -1.5rem;
}

fieldset > legend > span {
  font-weight: 200;
}

label {
  font-weight: 300;
}
.input::placeholder {
  font-size: "0.8rem" !important;
  font-weight: 300 !important;
}

input::placeholder {
  font-size: "0.5rem" !important;
  font-weight: 300 !important;
}

.MuiTextField-root {
  font-weight: 200;
}

button.MuiButton-containedPrimary {
  background-color: #0cb36d !important;
}

button.MuiButton-containedPrimary:hover {
  background-color: #0cb36d !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: normal !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: normal !important;
}

#demo-simple-select-outlined {
  font-weight: 300;
  font-size: 0.8rem;
}

.footer {
  margin-bottom: "-5%";
  font-size: "0.8rem";
  color: "#00000060";
}

.footer-link {
  color: "#00000060";
}

.btn {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
}
.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 5px 10px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.no-record{
  color: gray;
}

#logo {
  margin-bottom: 2.2rem;
}

.language-menu-item-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem;
  height: 2.1rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.4rem;
  background-color: #5d78ff;
  margin-inline-end: 1rem;
}

.language-menu-item-label-condensed {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  color: #fff;
  font-weight: bold;
  border-radius: 0.4rem;
  background-color: #0cb36d;
  margin-inline-end: 0.5rem;
}

.MuiTabs-flexContainer {
  color: #0cb36d;
}

.MuiTabs-indicator {
  background-color: #0cb36d !important;
}

.MuiTab-root {
  text-transform: none !important;
  font-size: 0.9rem !important;
}
.Toastify__toast-container{
  z-index: 999999999;
}
.swal2-popup{
  padding: 15px !important;
}
.swal2-container #swal2-title{
  font-size: 19px;
  padding: 0;
  text-align: left;
}
#swal2-html-container{
  font-size: 15px;
  margin-left: 0;
  text-align: left;
  color: black;
}
.swal2-actions{
  margin-right: 0;
  margin-top: 35px;
}
.swal2-actions > button {
  border: 0;
	border-radius: 0.25em;
	color: #fff;
	font-size: 1.0625em;
  margin: 0;
  margin-right: 10px;
  padding: 6px 20px;
  font-size: 15px;
  box-shadow: none;
}
.swal2-confirm {
	background-color: #2778c4 !important;
}
.swal2-deny {
	background-color: #d14529 !important;
}
.swal2-cancel {
	background-color: #757575 !important;
}
.cursor-pointer{
  cursor: pointer;
}